@import "../base/_variables.scss";

@mixin input-placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
  }

input[type="text"],
input[type="date"],
input[type="tel"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="quantity"],
select,
textarea {
    @media(max-width: $screen-xs-max) {
        font-size: 16px;
    }

    letter-spacing: 0.1rem;
    border-radius: 0;
    color: $white;
    padding: $base-padding / 2;
    background-color: transparent;
    border: 1px solid $white;
    padding: 1.3rem 1rem;
    width: 100%;
    font-family: $font-raleway;

    @include input-placeholder {
        color: $gray-semidark;
    }

    &:focus {
        outline: none;
    }

    &.input-orange {
        color: $gray-dark;
        border-color: $orange;
    }

    &.input-whitetext {
        color: white !important;
    }

    &.input-search {
        @include input-placeholder {
            color: $gray-semidark;
        }

        font-family: $font-abril-text-italic;
        color: $gray-dark;
        border: 0;
        font-size: $base-fontsize * 3;
        border-bottom: 1px solid $gray-semilight;
        padding-left: 7rem;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_search.svg");
        background-repeat: no-repeat;
        background-position: left;
        background-size: 5rem 5rem;
        position: relative;

        @media(min-width: $screen-sm) {
            font-size: $base-fontsize * 3.37;
        }

        &.input-search--grey {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_search_grey.svg");
            border-color: $gray-semidark;
        }
    }

    &.input-centered {
        text-align: center;
    }

    &.phonenumber {
        max-width: 220px;
    }
}

.search-holder {
    position: relative;

    .alerttext {
        position: absolute;
        left: 0;
        bottom: -2.5rem;
        font-size: 1.7rem;
        background-repeat: no-repeat;
        background-size: 100%;
        font-weight: 500;

        &.alerttext--info {
            color: $gray-dark;
            opacity: 1;

            @media screen and (min-width: $screen-md) {
                opacity: 0;
            }
        }

        &.alerttext--warning {
            animation: blink 1s linear;
            animation-iteration-count: 1;
            color: $orange;

            @keyframes blink {
                50% {
                    opacity: 0.0;
                }
            }
        }
    }
}

input[type="search"] {
    -webkit-appearance: none;
}

input[type=range] {
    -webkit-appearance: none;
    /*required for proper track sizing in FF*/
    width: 100%;
    background: transparent;
    padding: 0 0 0 0;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: $white;
    border: none;
    //border-radius: 3px;
    margin: 5px 0 5px 0;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $orange;
    margin-top: -6px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: $white;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    background: $gray-semidark;
    border: none;
}

input[type=range]::-moz-range-progress {
    height: 5px;
    background: $gray-dark;
}

input[type=range]::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $orange;
}
/*hide the outline behind the border*/
input[type=range]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
}

input[type=range]:focus::-moz-range-track {
    background: $gray-semidark;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;
    /*remove default tick marks*/
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: $gray-dark;
}

input[type=range]::-ms-fill-upper {
    background: $gray-semidark;
}

input[type=range]::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $orange;
}

input[type=range]:focus::-ms-fill-lower {
    background: $gray-dark;
}

input[type=range]:focus::-ms-fill-upper {
    background: $gray-semidark;
}

form {
    @include input-placeholder {
        font-size: 2.12rem;
    }
}

.form-group {
    margin: $base-margin 0 $base-margin 0;

    &.top-padding {
        padding-top: $gutter-padding;
    }
}

.form-input {
    margin: $base-margin 0 $base-margin 0;

    .field-validation-error, .field-validation-valid {
        height: 2rem;
        display: block;
        margin-top: 0.5rem;
    }

    &.radio {
        label {
            display: block;
        }
    }

    &.checkbox {
        margin-bottom: 2rem;
    }

    label {
        display: none;
    }
}

form {
    &.with-labels {
        .form-input {
            label {
                color: $gray-semidark;
                display: block;
            }
        }
    }
}

select {
    appearance: none;
    -webkit-appearance: none; // this one needs to be prefixed since it is non-standard
    background: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_white.svg") no-repeat;
    background-size: 2rem 2rem;
    background-position: center right 1rem;
    background-repeat: no-repeat;

    &::-ms-expand {
        display: none; /*Removes default arrow from IE*/
    }

    option {
        &.upper {
            text-transform: uppercase;
        }
    }

    &.light {
        color: black;
        border-color: $orange;

        option {
            background-color: $white;
        }
    }

    &.select__dropdown--orange {
        background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_orange.svg");
    }

    &.select__dropdown--white {
        background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_white.svg");
    }

    &.select__dropdown--dark {
        color: $gray-dark;
        border-color: $gray-dark;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_dark.svg");
    }
}

textarea {
    vertical-align: bottom;

    &.expanded {
        height: 200px;
    }
}

.dark-section {
    .xform {
        p {
            color: $white;
        }

        input:not([type='submit']):not([type='button']):not([type='range']), textarea, select {
            background-color: $white;
            color: $gray-dark;
        }

        input[type="submit"] {
            color: $white;
        }

        .loader {
            border-top: 0.5em solid rgba(255, 255, 255, 0.2);
            border-right: 0.5em solid rgba(255, 255, 255, 0.2);
            border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
            border-left: 0.5em solid $white;
        }
    }
}

.form-dark, .EPiServerForms {
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $beige-orange inset;
    }

    .loader {
        display: none;
    }

    .js-form-result-message {
        margin-bottom: 2rem;
    }

    input:not([type='submit']):not([type='button']):not([type='range']), textarea, select {
        border-color: $orange;
        color: $gray-dark;
    }

    input[type='submit'], button[type='submit'] {
        border-color: $orange;
        color: $gray-dark;
        font-size: $base-fontsize*1.5;

        &.disabled, :disabled {
            cursor: default;
            color: $gray-semidark;
            background-color: $gray-semilight;
            border-color: $gray-semidark;
            pointer-events: none;
        }
    }

    select {
        background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_orange.svg");
    }

    .field-validation-valid {
        margin-bottom: 1rem !important;
    }

    .field-validation-error {
        margin-bottom: 1rem !important;
        font-weight: 600 !important;
    }

    &.form-dark--alternate {
        color: $white;

        p {
            color: white;
        }

        input[type='submit'] {
            color: white;
        }

        input:not([type='submit']):not([type='button']):not([type='range']), textarea, select {
            background-color: $white;
            border: none;
            color: $gray-dark;
        }

        .input-validation-error {
            background-color: $validation-color !important;

            @include input-placeholder {
                color: $white;
            }
        }
    }
}
.form-withlabels {
    label {
        display: block;
    }

    .form-input {
        margin: 0;
    }
}

.editmode {
    .settings-input {
        .form-input {
            min-height: 88px;
        }
    }
}
.not-found-title {
    font-family: "AbrilText-Italic",Georgia;
    font-size: 4.5rem;
    font-style: italic;
}
.not-found-text {
    font-family: Raleway;
    font-size: 2rem;
    line-height: 1.5;
}

.thankyou-response-holder {
    padding-bottom: 23.5rem;

    @media(max-width: $screen-md){
        padding-bottom:0;
    }
}

.viewing-booking-form {
    .form-input {
        label {
            color: $gray-semidark;
            display: block;
        }
    }

    label {
        margin-bottom: 0.5rem;
    }
}

.captcha {
    .captcha-widget {
        display: none;
    }

    .captcha-loader {
        width: 100%;
        height: 10px;
        color: $orange;
        margin-bottom: 1.5rem;
        display: flex;

        &.center {
            justify-content: center;
        }

        &.left {
            justify-content: left;
        }

        &.hidden {
            visibility: hidden;
        }

        > .loader-container {
            margin-left: 1rem;
            display: flex;
            align-items: flex-end;

            .loader-item {
                margin-left: 2px;
                margin-right: 2px;
                animation: showAndHide 2.5s ease-in-out infinite;
                background: $orange;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                opacity: 0;

                &:nth-child(1) {
                    animation-delay: 0.2s;
                }

                &:nth-child(2) {
                    animation-delay: 0.4s;
                }

                &:nth-child(3) {
                    animation-delay: 0.6s;
                }
            }
        }

        @keyframes showAndHide {
            0% {
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            60% {
                opacity: 1;
            }

            100% {
                opacity: 0;
            }
        }
    }
}

.EPiServerForms {
  label {
    margin-bottom: 0.5rem;
  }
  input, textarea, select {
    margin-bottom: 0.5rem;
  }
}

.EPiServerForms .ValidationRequired .Form__Element__Caption:after {
  content: " ✶";
}
