﻿@import "../base/_variables.scss";
@import "../misc/_animations.scss";

.movesta--avm-widget-root {
	//The widget has different steps and they require different amount of space.
	//Min-height must be set to max-height of the most space demanding step.
	min-height: 400px;
}

.movesta--avm-form {
	margin: auto;
}

.movesta--step-container{
	align-items: normal !important;
}

.movesta--step-heading {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	text-align: center;
}

.movesta--step-description {
	margin-top: 5px;
	margin-bottom: 0;
	text-align: center;
}

a.movesta--link {
	color: $orange;
	font-weight: bold;
	text-decoration: none;
}

a.movesta--link:not(.movesta--link-no-hover):hover {
	text-decoration: underline;

}

a.movesta--link-no-hover {
	width: 50%;

	[class^="movesta--button-"] {
		width: 100%
	}
}

.circular-progress {
	color: $orange;
}

/* general form elements */
.movesta--text-field {
	@extend .input-orange;
	display: block;
	outline: none;
}

.movesta--text-field-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-bottom: 10px;
}

.movesta--radio-container, .movesta--checkbox-container, .movesta--text-field-container {
	width: 100%;
}

[class^="movesta--button-"] {
	width: 50%;
}

.movesta--button-primary {
	background-color: $orange;
	color: #FFF;
	border: 1px solid $orange;
	text-transform: uppercase;
	padding-top: 10px;
	padding-bottom: 10px;
}

.movesta--button-primary:disabled {
	opacity: 0.5;
}

.movesta--button-primary:not(:disabled):hover {
	background-color: $gray-darker;
	border: 1px solid $gray-darker;
	color: #FFF;
}

.movesta--button-secondary {
	background: #fff;
	color: $gray-darker;
	border: 1px solid $orange;
	text-transform: uppercase;
}

.movesta--button-secondary:not(:disabled):hover {
	background: $gray-darker;
	color: #fff;
	border: 1px solid $gray-darker;
}

.movesta--button-secondary:disabled {
	background: #adadad;
	color: #fff;
	border: none;
}

.movesta--housing-type-buttons-wrapper {
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 10px;
}

.movesta--housing-type-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding: 20px;
	gap: 5px;
	width: 20%;

	@media screen and (max-width: $screen-xs-min) {
		padding: 10px;
	}
}

.movesta--housing-type-button-active {
	background-color: $light-orange;
	color: white;
	border-radius: 8px;
}

.movesta--housing-type-button:hover {
	color: $light-orange;
	background-color: white;
}

.movesta--checkbox {
	margin-top: 10px;
	margin-bottom: 10px;
}

.movesta--form-error {
	color: $orange;
	font-weight: 600;
	padding-bottom: 0px !important;
}

​ /* street address autocomplete */
.movesta--autocomplete-input-with-options {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.movesta--autocomplete-options-wrapper {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	border: none;
}

.movesta--autocomplete-options-wrapper:not(:empty) {
	border: 1px solid $gray-darker;
	border-top: none;
}

.movesta--autocomplete-option {
	text-align: left;
	border: none;
	font-size: inherit;
	border-top: 1px solid $gray-darker;
	width: 100%;
	padding: 10px;
	background-color: white;
	border-radius: 0;
}

.movesta--autocomplete-option:hover {
	background-color: $light-orange;
}

.movesta--autocomplete-option-no-street-number {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-weight: bold;
}

.movesta--circular-progress {
	height: 37px;
	color: $orange;
}

​ /* valuation result */
.movesta--valuation-wrapper {
	text-align: center;
	max-width: 400px;
	margin: 0 auto;
	padding-top: 2em;
}

.movesta--valuation-heading {
	margin-bottom: 0.5em;
}

.movesta--valuation-estimate {
	font-size: 2rem;
	margin-bottom: 2em;
}

.movesta--valuation-range {
	margin-bottom: 2em;
}

.movesta--valuation-error {
	color: $orange;
	font-weight: bold;
}