@import "../base/_variables.scss";

/*Fade up effect of the text on the start page, one letter at a time - added through method in subsite-animations.ts*/
.subsite-start-page {
    .introsection__subheading {
        .heading-wrapper {
            &.hide {
                opacity: 0;
            }

            .letter {
                opacity: 0;
                position: relative;
                animation: move-letter 0.5s forwards;
            }

            $number-of-letters: 50;

            @for $i from 1 through $number-of-letters {
                .letter:nth-child(#{$i}) {
                    animation-delay: #{200 + ($i * 50)}ms;
                }
            }
        }
    }

    @keyframes move-letter {
        0% {
            bottom: -5rem;
        }

        100% {
            bottom: 0;
            opacity: 1;
        }
    }
}
