﻿@import "../base/_variables.scss";

#CookieDeclaration {
  margin: 0 auto !important;
  max-width: 800px !important
}

#CookieDeclaration h3 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 2rem;
  margin-top: 3rem !important;
  position: relative;
  text-align: center
}

#CookieDeclaration h3:after {
  background: #7f757150;
  bottom: -1rem;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 6.5rem
}

#CookieDeclaration h4 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1rem !important;
  margin-top: 2rem !important
}

#CookieDeclaration p {
  font-size: 1.125rem;
  line-height: 1.875rem;
  text-align: center
}

#CookieDeclaration p a {
  background-color: initial;
  border: 1px solid #000;
  border-radius: 0;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-family: NeutraPremium, sans-serif;
  font-size: 1.25rem;
  margin: 2rem auto !important;
  outline-offset: 5px;
  padding: .5rem 1.5rem;
  text-decoration: none;
  transition: background-color .2s ease, color .2s ease
}

#CookieDeclaration p a:disabled {
  cursor: not-allowed
}

@media (prefers-reduced-motion) {
  #CookieDeclaration p a {
    transition: none
  }
}

#CookieDeclaration p a:hover:not(:disabled) {
  background-color: #0000001a
}

#CookieDeclaration p a:focus, #CookieDeclaration p a:focus-visible {
  outline-color: #000
}

#CookieDeclaration p a:active {
  background-color: #0000004d
}

#CookieDeclaration p ~ table {
  margin-top: 2rem
}

#CookieDeclaration table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
  width: 100%
}

#CookieDeclaration table th {
  background-color: $gray-dark;
  color: $white;
  white-space: nowrap
}

#CookieDeclaration table td, #CookieDeclaration table th {
  border: 1px solid $gray-darker;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.875rem;
  padding: 1rem !important;
  text-align: left
}

#CookieDeclaration table td {
  background-color: initial;
  width: 100%
}

html > body > #CookieConsent {
  h1 {
    font-size: 3.5rem;
    font-family: $font-abril-text;
    line-height: 1.15;
  }
  .cookie-popup {
    --color_primary: #{$orange};;

    > div {
      border-radius: 0;
    }

    .actions > div {
      button {
        @extend .button;
        @extend .button-blacktext;
        @extend .button-blacktext--transparent;

        &.accentuated {
          @extend .button-important;
        }
      }
    }
  }
}
