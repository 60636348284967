@import "../base/_variables.scss";

.is-touch {
    .top {
        background-attachment: scroll;
    }
    .fullscreen-bg {
        background-attachment: scroll;
    }
}
