@import "../base/_variables.scss";

//Only used on EventListingPage. Remove in EOO-1584
.freewall {
    margin: $grid-gutter-width /2;
}

//Only used on EventListingPage. Remove in EOO-1584
.freewall {
    .brick {
        &.brick--medium {
            width: 480px;
            height: 320px;

            .brick__body {
                width: 50%;
            }

            .brick__button {
                right: 2.5rem;
                transform: none;
            }
        }

        &.brick--large {
            width: 480px;
            height: 640px;

            .brick__body {
                width: 50%;
            }
        }
    }
}

//Only used for TeaserBlock. Remove in EOO-1584
.brick {
    float: left;
    width: 320px;
    height: 160px;

    .brick__header {
        position: absolute;
    }

    .brick__body {
        position: absolute;

        p {
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            margin-top: 1rem;
        }

        &.hidden {
            p {
                display: none;
            }
        }

        width: 100%;
    }

    .brick__button {
        position: absolute;
        bottom: 2.5rem;
        right: 50%;
        transform: translateX(50%);
    }

    .brick__inner {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 2.5rem;
        word-break: break-word;
        background-color: $black-transparent;
        transition: background-color 0.5s ease;

        .brick__inner-overlay {
            opacity: 0;
            transition: opacity 0.5s ease;
            position: absolute;
            background-color: rgba(0,0,0,0.3);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .brick__inner-heading {
            margin-right: 2.5rem;
            position: absolute;
        }

        &:hover {
            background-color: $gray-darker;

            h2 {
                transition: color 0.5s ease;
                color: $white;
            }

            h1 {
                transition: color 0.5s ease;
                color: $white;
            }

            .brick__body p {
                display: block;
            }
        }

        &.imageVisible {
            background-size: cover;
            background-position: center top;

            &:hover {
                .brick__inner-overlay {
                    opacity: 1;
                }
            }
        }

        &.brick__inner--orange {
            background-color: $orange;

            &:hover {
                background-color: $beige-orange;
            }
        }

        &.brick__inner--grey {
            background-color: $gray-light;

            &:hover {
                background-color: $light-orange;
            }
        }

        &.brick__inner--header {
            background-color: $gray-light;

            h1 {
                > span {
                    color: $gray-dark;
                    word-break: normal;
                    display: block;

                    &.orange {
                        color: orange;
                        margin-top: 3rem;
                    }
                }
            }
        }
    }
}
