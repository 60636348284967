@import "../base/_variables.scss";

table {
    width: 100%;
    white-space:nowrap;
    tr {
        th {
            width: 33%;

            @extend strong;
            padding-bottom: $base-padding;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }

        td {
            width: 33%;
            text-align: center;
            padding: $base-padding/2 0;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }
}