@import "../base/_variables.scss";

#horizontal-menu {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 100000;
    width: 100vw;
    // This is a nasty solution but it is to much work to get rid
    // off it right now..
    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;
        padding: 15px 17px;

        @media screen and (min-width: $mobile-menu-breakpoint) {
            padding: 21px 35px;
        }

        background-color: rgba(242, 242, 242, 0); // $gray-light but fully transparent
        transition: background-color 0.5s ease-in-out;

        &.active {
            background-color: $gray-light-transparent;
        }

        &.menu-background {
            background-color: $white-transparent;
        }

        &.menu-background-dark {
            background-color: $gray-transparent;

            #menu-overlay {
                .list {
                    a {
                        color: $white;
                    }

                    .menu-item::after {
                        background-color: $white;
                    }
                }
            }

            .navigation-button {
                background: inline-image("../../../wwwroot/Static/Images/eo_hamburger_white.svg") no-repeat center;
            }

            .search-button {
                a {
                    background: inline-image("../../../wwwroot/Static/Images/eo_search_white.svg") no-repeat center;
                }
            }

            .logo-link > .eo-logo-dark {
                display: none;
            }

            .logo-link > .eo-logo-light {
                display: block;
            }
        }
    }

    .logo-link {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        &.active {
            opacity: 1;
        }

        .eo-logo {
            width: 171px;
            height: 35px;
            max-width: 171px;

            &.commercial-logo {
                width: 151px;
                height: 45px;
                max-width: 151px;

                @media screen and (min-width: $mobile-menu-breakpoint) {
                    height: 50px;
                    width: 171px;
                }
            }
        }

        .eo-logo-light {
            display: none;
        }
    }

    .navigation-button {
        background: inline-image("../../../wwwroot/Static/Images/eo_hamburger.svg") no-repeat center;
        z-index: 120000;
        height: 40px;
        width: 40px;
        margin-right: 12px;

        &.active {
            background: inline-image("../../../wwwroot/Static/Images/eo_hamburger_cross.svg") no-repeat center;
        }
    }

    .search-button {
        height: 40px;

        a {
            display: block;
            height: 100%;
            background: inline-image("../../../wwwroot/Static/Images/eo_search.svg") no-repeat center;
            width: 40px;

            @media screen and (min-width: $mobile-menu-breakpoint) {
                width: 48px;
            }

            @media screen and (min-width: $screen-lg) {
                width: 50px;
            }
        }
    }

    #menu-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 110000;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        background-color: $black-transparent;
        transition: opacity 0.1s linear;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;

        &.active {
            visibility: visible;
            opacity: 1;
        }

        .menu-overlay-inner {
            min-width: 35rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            text-align: center;
            cursor: default;
        }

        .menu-overlay__logo {
            display: inline-block;
            border-bottom: 2px solid #F39200;
            padding-bottom: 2rem;
            margin-bottom: 2rem;

            img {
                width: 21.5rem;
            }
        }

        .list-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .list {
            width: 100%;

            @media screen and (min-width: $mobile-menu-breakpoint) {
                max-width: 1050px;

                li {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            @media screen and (max-width: $small-desktop-menu-breakpoint) {
                li {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            @media screen and (min-width: $screen-lg) {
                li {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            a {
                @extend .inverted;
                display: inline-block;
                font-family: "AbrilText", Georgia;
                text-decoration: none;
                letter-spacing: 0.2rem;
                font-size: 3.5rem;
                line-height: 1.30;
                white-space: nowrap;
            }
        }

        .menu-item::after {
            content: '';
            display: block;
            transform: scaleX(0);
            transition: transform 300ms ease-in-out;
            width: 100%;
            height: 2px;
            background-color: $white;
        }

        .menu-item:hover::after {
            transform: scaleX(1);
        }
    }

    &.newproduction {
        > div {
            @media screen and (min-width: $mobile-menu-breakpoint) {
                padding: 10px 35px;
            }

            .logo-link {
                .eo-logo {
                    width: 151px;
                    height: 40px;
                    max-width: 151px;

                    @media screen and (min-width: $mobile-menu-breakpoint) {
                        height: 50px;
                        width: 171px;
                    }
                }
            }
        }

        #menu-overlay, .search-button {
            @media screen and (min-width: $mobile-menu-breakpoint) {
                transform: translateY(-50%);
            }
        }

        #navigation-button, .search-button {
            @media screen and (max-width: $mobile-menu-breakpoint) {
                transform: translateY(-20%);
            }
        }
    }

    &.subsite-menu {
        > div {
            @media screen and (min-width: $mobile-menu-breakpoint) {
                padding: 10px 35px;
            }
        }

        #menu-overlay, .search-button {
            @media screen and (min-width: $mobile-menu-breakpoint) {
                transform: translateY(-50%);
            }
        }

        #navigation-button, .search-button {
            @media screen and (max-width: $mobile-menu-breakpoint) {
                transform: translateY(-20%);
            }
        }
    }
}

body.active {
    #horizontal-menu {
        .logo-link,
        .search-button {
            display: none;
        }
    }
}

.menu-as-navigation-bar {
    #horizontal-menu {
        position: absolute;

        .logo-link {
            opacity: 1;

            .eo-logo {
                width: 136px;
                height: 28px;
                max-width: 136px;

                @media screen and (min-width: $mobile-menu-breakpoint) {
                    width: 118px;
                    height: 28px;
                    max-width: 118px;
                }

                @media screen and (min-width: $screen-lg) {
                    width: 171px;
                    height: 35px;
                    max-width: 171px;
                }

                &.commercial-logo {
                    width: 151px;
                    height: 45px;
                    max-width: 151px;

                    @media screen and (min-width: $mobile-menu-breakpoint) {
                        height: 50px;
                        width: 171px;
                    }
                }
            }

            .eo-logo-dark {
                display: block;
            }

            .eo-logo-light {
                display: none;
            }
        }

        .navigation-button {
            height: 40px;
            width: 40px;
            background: inline-image("../../../wwwroot/Static/Images/eo_hamburger_dark.svg") no-repeat center;

            &.active {
                background: inline-image("../../../wwwroot/Static/Images/eo_hamburger_cross_white.svg") no-repeat center;
            }
        }

        .search-button {
            height: 40px;

            @media screen and (min-width: $mobile-menu-breakpoint) {
                height: 28px;
            }

            a {
                background: inline-image("../../../wwwroot/Static/Images/eo_search_dark.svg") no-repeat center;
                width: 40px;

                @media screen and (min-width: $mobile-menu-breakpoint) {
                    width: 48px;
                }

                @media screen and (min-width: $screen-lg) {
                    width: 50px;
                }
            }
        }

        @media screen and (min-width: $mobile-menu-breakpoint) {
            .navigation-button {
                display: none;
            }

            #menu-overlay {
                position: relative;
                width: auto;
                height: auto;
                background-color: transparent;
                visibility: visible;
                opacity: 1;
                flex-grow: 1;

                .menu-overlay-inner {
                    min-width: 0;
                    position: relative;
                    left: auto;
                    top: auto;
                    transform: none;
                    text-align: left;
                    cursor: default;
                }

                .menu-overlay__logo {
                    display: none;
                }

                .menu-item::after {
                    background-color: $gray-dark;
                }

                .list {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: baseline;
                    flex-grow: 1;

                    a {
                        display: inline-block;
                        font-family: $font-raleway;
                        text-decoration: none;
                        letter-spacing: 0.2rem;
                        font-size: 1.5rem;
                        line-height: 1.30;
                        text-transform: uppercase;
                        color: $gray-dark;

                        &:hover,
                        &:active {
                            font-weight: bold;
                        }

                        @media screen and (max-width: $small-desktop-menu-breakpoint) {
                            font-size: 1.4rem;
                        }
                    }
                }
            }

            .search-button {
                width: 171px;
                position: relative;
                padding-left: 20px;

                @media screen and (min-width: $mobile-menu-breakpoint) {
                    width: 118px;
                    max-width: 118px;
                }

                @media screen and (min-width: $screen-lg) {
                    width: 171px;
                }

                a {
                    display: block;
                    height: 100%;
                    background: inline-image("../../../wwwroot/Static/Images/eo_search_dark.svg") no-repeat center;
                    width: 24px;
                    position: absolute;
                    right: 0;
                    bottom: -9px;
                }
            }
        }
    }
}

body.active.menu-as-navigation-bar{
    #horizontal-menu {
        @media screen and (min-width: $mobile-menu-breakpoint) {
            #menu-overlay {
                display: none;
            }
        }
    }
}

.sticky-menu {
    position: fixed !important;
    top: 0;
    height: $horizontal-menu-height-mobile;

    @media screen and (min-width: $screen-sm) {
        height: $horizontal-menu-height-small-tablet;
    }

    @media screen and (min-width: $screen-md) {
        height: $horizontal-menu-height-tablet;
    }

    @media screen and (min-width: $mobile-menu-breakpoint) {
        height: $horizontal-menu-height-small-desktop;
    }

    @media screen and (min-width: $small-desktop-menu-breakpoint) {
        height: $horizontal-menu-height-desktop;
    }
}

// Below is some old stuff restored because from the old navigation stuff

.subnavigation-smaller{
    span {
        font-size: 80%;
    }
}

.subnavigation-mobile {
    z-index: 9999999;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;


    &.visible {
        max-height: 1000px;
        transition: max-height 0.5s ease-in;
    }

    .toggle-carrot {
        pointer-events: none;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_dark.svg");
        background-repeat: no-repeat;
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        z-index: 1;
        top: 0;
        transform: translateY(50%);

        &:hover {
            cursor: pointer;
        }
    }

    > div > ul {
        padding-left: 0;

        > li > div > a {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    ul {
        padding-left: 2rem;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out;

        &.expanded {
            max-height: 1000px;
            transition: max-height 0.5s ease-out;

            ~ .toggle-carrot {
                transform: rotate(90deg) translateX(40%) translateY(25%);
            }
        }

        li {
            position: relative;

            > div {
                padding-left: 2rem;

                &:hover {
                    background-color: #feefdb;
                    cursor: pointer;
                }

                a {
                    padding: 1rem 0;
                    display: inline-block;
                }
            }

            &.active {
                > div {
                    background-color: #feefdb;
                }
            }
        }
    }
}

