@import "../base/variables";

.image-slideshow {
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.fake-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    height: 100vh;
    width: 100vw;
    z-index: 9999999999;
  }

  li {
    position: relative;

    &:before {
      background-image: inline-image("../../../wwwroot/Static/Images/eo_loader.svg");
      content: '';
      position: absolute;
      left: 50%;
      height: 100px;
      width: 100px;
      background-size: cover;
      transform: translate(-50%, -50%);
      top: 50%;
      z-index: -1;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    max-height: 100vh;
  }

  .list-images__description {
    display: none;
  }

  .fullscreen-trigger {
    display: none;
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: $base-padding;
    right: $base-padding;
    display: flex;
    height: 25px;
    width: 25px;
    background-repeat: no-repeat;
    background-image: inline-image("../../../wwwroot/Static/Images/eo_cross_inverted.svg");
  }

  .slideshow-info {
    position: absolute;
    right: $base-padding;
    bottom: $base-padding;
    background: $white-transparent;
    font-size: $base-fontsize * 3;
    padding: $base-padding;
  }

  .slider-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: $base-fontsize * 2;
    background: $white-transparent;
    border: 0;
    padding: $base-padding;
    left: $base-padding;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $screen-sm) {
      height: 50px;
      width: 50px;
    }

    &.next {
      left: auto;
      right: $base-padding;
    }
  }
}

.fullscreen-trigger {
  position: absolute;
  right: 0;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    display: none;
    @media screen and (min-width: $screen-sm) {
      display: block;
    }
  }

  .icon {
    background-image: inline-image("../../../wwwroot/Static/Images/eo_fullscreen.svg");
  }
}
