﻿@import "../base/_variables.scss";

.contact-requests {
    table {
        margin-top: 20px;
    }

    table th, table td {
        text-align: left !important;
        width: auto !important;
        padding: 3px !important;
    }

    .warning {
        background-color: #E3868B;
    }

    input[type='date'] {
        letter-spacing: 0.1rem;
        border: 1px solid $orange;
        padding: .5rem;
        background-color: transparent;
        padding: 1.3rem 1rem;
        font-family: "Raleway",sans-serif;
    }

    .absolutebottom {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    label {
        display: block;
    }

    .error {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 700px;
        height: 500px;
        background-color: pink;
        padding: 20px;
        z-index: 1;
        overflow: scroll;
    }

    .error a {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 25px;
    }

    .list-bullets li {
        margin-bottom: 2rem;
    }
}

.campaign-registration {
    .error {
        position: fixed;
        bottom: 0;
        left: 0;
        max-width: 700px;
        max-height: 500px;
        background-color: pink;
        padding: 20px;
        z-index: 1;
        overflow: scroll;
    }

    .error a {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 25px;
    }

    #selected-image-wrapper {
        margin-top: 2.5rem;
        max-width: 50%;
    }

    input[type="checkbox"] {
        height: 25px;
        width: 25px;
        display: block;
    }

    .mandatory:before {
        font-size: 2.5rem;
        content: '*';
    }

    select {
        display: block;
        margin-bottom: 10px;
    }

    .multiselect {
        display: block;
        margin-bottom: 10px;
        background-image: none !important;
    }

    .wrapper {
        overflow: auto;
    }

    .help-text {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.25rem;
    }
}
