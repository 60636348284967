@import "../base/_variables.scss";

@mixin button-full {
    text-align: center;
    width: 100%;
}

.button, button[type='submit']:not(.movesta--button-primary) {
    -webkit-appearance: none;
    border-radius: 0;
    @extend .button-text;
    padding: 1.45rem 1.5rem;
    border: 1px solid $white;
    text-decoration: none;
    color: $white;
    background-color: transparent;
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: normal;

    @media(min-width: $screen-sm) {
        padding: 1.3rem 1.5rem;
    }

    @media(min-width: $screen-md) {
        padding: 1.19rem 1.5rem;
    }

    &:hover {
        background-color: $gray-darker !important;
        color: $white !important;
        -webkit-text-fill-color: $white;
        border-color: $gray-darker !important;
    }

    &.button-standard {
        border: 1px solid $orange;
        color: $gray-darker;
    }

    &.button-important {
        background-color: $orange;
        color: $white;
        border: 1px solid $orange;
    }

    &.button-full {
        @include button-full;
    }

    &.button-blacktext {
        color: $gray-dark;
        -webkit-text-fill-color: $gray-dark;
        background-color: $white;

        &.button-blacktext--transparent {
            background-color: transparent;
            border-color: $gray-dark;
        }
    }

    &.button-whitetext {
        color: $white;
    }

    &.button-white-background {
        background-color: #fff;
    }

    &.button-back {
        padding-left: 4rem;
        position: relative;

        &:after {
            content: "";
            background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_orange.svg");
            background-position: center left;
            position: absolute;
            top: 0;
            left: 1rem;
            height: 100%;
            width: 2rem;
            background-repeat: no-repeat;
            transform: rotate(90deg);
        }
    }

    &.button-forward {
        padding-right: 4rem;
        position: relative;

        &:after {
            content: "";
            background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_orange.svg");
            background-position: center right;
            position: absolute;
            top: 0;
            right: 1rem;
            height: 100%;
            width: 2rem;
            background-repeat: no-repeat;
            transform: rotate(270deg);
        }
    }

    &:disabled {
      cursor: not-allowed !important;
      color: $gray-semidark !important;
      background-color: $gray-semilight !important;
      border-color: $gray-semidark !important;
      pointer-events: none !important;
    }

    &.button-disabled {
        cursor: not-allowed !important;
        color: $gray-semidark !important;
        background-color: $gray-semilight !important;
        border-color: $gray-semidark !important;
        pointer-events: none !important;
    }

    &.button-images {
        padding-right: 6rem;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_camera.svg");
        background-size: 3rem 3rem;
        background-repeat: no-repeat;
        background-position: right 1.7rem center;
    }

    &.button-coverage {
        padding-left: 6rem;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_bell.svg");
        background-size: 3rem 3rem;
        background-repeat: no-repeat;
        background-position: left 1.7rem center;
    }

    &.button-draw {
        padding-left: 6rem;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_pencil.svg");
        background-size: 3rem 3rem;
        background-repeat: no-repeat;
        background-position: left 1.7rem center;
    }

    &.button-transparent {
        background-color: $lightgrey-transparent;
        border-color: $lightgrey-transparent;
    }

    &.toggle-button {
        border-color: $gray-darker;
        background-color: $white;
        color: $gray-darker;
        font-size: 11.3px;

        &:hover, &.active {
            background-color: $gray-darker;
            color: $white;
            -webkit-text-fill-color: $white;
        }
    }

    &.button-half {
        width: 50%;
        text-align: center;
    }

    &.button-pair {
        width: 50%;
        text-align: center;

        &:nth-child(2) {
            border-left: none;
        }
    }

    &.button-badge {
        border-radius: 2rem;
        font-size: 1.1rem;
        padding: 1rem;
    }

    &.filter-button {
        border: 1px solid $orange;
        color: $gray-darker;

        &:hover {
            border-color: $gray-darker;
            background-color: $gray-darker;
            color: $white;
        }

        &.active {
            background-color: $orange;
            color: $white;
        }
    }
}

.button-as-text {
    @extend p;
    background: none;
    border: none;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600 !important;
}

.toggle-buttons {
    .button {
        margin-left: -4px;
    }
}

.button-round {
    background-image: inline-image("../../../wwwroot/Static/Images/eo_cross.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
    height: 2.9rem;
    width: 2.9rem;
    text-indent: 9999px;
}


.split-buttons {
    .split-buttons__button {
        width: 49.5%;
        float: left;

        &:first-child {
            margin-right: 1%;
        }

        &.full-xs {
            @media(max-width: $screen-xs) {
                @include button-full;
                margin-bottom: 2rem;
            }
        }
    }
}
