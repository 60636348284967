@import "../base/_variables.scss";

.is-mac, .is-ios {
    .meta-heading {
        margin-bottom: 0.5rem;
    }

    .property-container {
        h5 {
            padding-bottom: 0.5rem;
        }

        h2 {
            padding-top: 1.4rem;
        }

        &.property-listing {
            h2 {
                padding-top: 1.4rem;
            }
        }
    }

    .image-container {
        h5 {
            padding-bottom: 0.5rem;
        }

        h2 {
            &.small-margin {
                margin-top: 1.4rem;
            }
        }
    }

    .property-summary {
        h2 {
            padding-top: 1.8rem;
        }
    }

    .introsection {
        .introsection__heading {
            margin-bottom: 3rem;
        }

        .introsection__subheading {
            margin-bottom: 3.5rem;
        }
    }
}