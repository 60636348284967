@import "../base/_variables.scss";
@import "../mixins/_clearfix.scss";


@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

.text-middle {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
}

.center {
    text-align: center;

    &.center__absolute {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        &.center__absolute--middle {
            top: 50%;
            transform: perspective(1px) translateY(-50%) translateX(-50%);
        }

        &.center__absolute--bottom {
            bottom: 2rem;
        }
    }
}

.block-section {
    margin-bottom: 10rem;
}

.block-background {
    padding-top: 10rem;
}

.section {
    margin-top: $base-margin-large;
    margin-bottom: $base-margin-large;

    &.section--small-space {
        margin-top: $base-margin-small;
        margin-bottom: $base-margin-small;
    }

    &.section--medium-space {
        margin-top: $base-margin-medium;
        margin-bottom: $base-margin-medium;
    }
    
    &.section--space-top-only {
        margin-bottom: 0;
    }

    &.section--space-bottom-only {
        margin-top: 0;
    }
}

.always-on-top {
    z-index: 2147483647;
}

body .property-container {
    .hide-if-js {
        display: none;
    }
}

.hide-if-js {
    display: none;
}

.no-js {
    .hide-if-js {
        display: block;
    }

    .hide-if-js--xs {
        display: block;
    }

    .hide-if-no-js {
        display: none;
    }
}

.tt-hint {
    display: none;
}

.right {
    float: right;
}

.text-right {
    text-align: right;
}

.middle {
    vertical-align: middle;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.no-flick {
    -webkit-transform: translate3d(0,0,0);
}

.invisible-if-empty {
    min-height: 0;
}

.clearfix {
    @include clearfix;
}

.relative {
    position: relative !important;
}

.inline {
    display: inline !important;
}
