@import "../base/_variables.scss";

.is-newproduction-page {
    /*Fade up effect of the text on the start page - added through method in nyprod-animations.ts*/
    .nyprod-start-page {
        .introsection__subheading {
            transform: none;
            transition: transform 1600ms, opacity 1600ms;
            opacity: 1;

            &.loading {
                transform: translateY(160px);
                opacity: 0;
            }
        }
    }
    /*Button animations on hover*/
    .button {
        transition: background-color 250ms, border-color 250ms;
    }

    .button:hover {
        background-color: $orange-transparent !important;
        transition: background-color 250ms, border-color 250ms;
        color: $white !important;
        border-color: $orange-transparent !important;
    }
    /*Overlay on the hero-image on the content pages*/
    .hero-image-container {
        picture::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(93, 58, 50,0.5),rgba(93, 58, 50,0.1),rgba(0,0,0,0.0),rgba(0,0,0,0.2));
        }
    }
    /*Adjusts the puff on the hero of the project page when using a device with a xs-screen*/
    .property-container__puff-container {
        &.nyprod-project-page-puff {

            @media(max-width: $screen-xs-max) {
                transform: scale(0.55);
                right: 2rem;
                top: 7rem;
            }
        }
    }
    /*An a-tag that makes the whole hero/image of a listed project clickable*/
    .nyprod-projectpage-link {
        display: block;
        width: 100%;
        height: 100%;
    }
    /*Buttons for filtering on the project listing page*/
    .nyprod-project-list-filter-button {
        width: 125px;
        border-radius: 1px;
        margin: 0.5rem 0.5rem;
        font-weight: 600;
        font-size: 1.3rem;

        &.active {
            background-color: $orange;
            color: $white;
        }
    }
    /*Animations applied on the project being intersected - is added through method in nyprod-animations.ts*/
    .row {

        &.nyprod-projects {

            .property-container {
                filter: brightness(60%);
                transition: filter 1s;

                &.visible {
                    filter: brightness(100%);

                    .property-container__project-overlay--inverted {
                        opacity: 0.5;
                    }

                    .property-container__content-container {
                        h6 {

                            &.newproduction {

                                &::after {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }
                }
                /*Underline effect on the h6 of project intersected*/
                .property-container__content-container {

                    h6 {

                        &.newproduction {
                            margin: 0;
                            padding: 0;
                            border-bottom: none;
                            line-height: 3;

                            &::after {
                                content: '';
                                display: block;
                                transform: scaleX(0);
                                transform-origin: left;
                                transition: transform 500ms ease-out 300ms;
                                height: 1px;
                                background-color: $orange;
                            }
                        }
                    }
                }
            }

            .property-container__clickarea {
                /*Animation of the "Read More" button on the project*/
                .button-whitetext {
                    transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
                    background-color: $orange;
                    border-radius: 3px;

                    &:hover {
                        transform: scale(1.07);
                        transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
                    }
                }
            }
        }
    }
} 