@import "../base/variables";

.list-plans {
    .fullscreen-trigger {
        bottom: 0;
        z-index: 2;
    }
}

ul.list {
    &.list-default {
        @extend p;

        > li {
            margin: 0;
            padding-left: 2rem;
            margin-bottom: 0.7rem;
            font-size: 2.1rem;
            color: $gray-dark;
            font-family: $font-abril-text-italic;
            position: relative;
            letter-spacing: 0.05rem;
            word-break: break-word;

            @media screen and (min-width: $screen-sm) {
                font-size: 1.8rem;
            }

            &:before {
                content: "\2022";
                position: absolute;
                left: 0;
            }
        }
    }

    &.list-inline {
        li {
            display: inline-block;

            > a {
                margin: 0;
            }
        }
    }



    &.list-table {
        display: table;
        width: 100%;

        li {
            display: table-cell;
            text-align: center;
        }
    }

    &.list-propertystatus {
        > li {
            display: inline-block;
            position: relative;
            margin-right: 3.5rem;

            &:last-child {
                margin-right: 0;
            }

            &:before {
                position: absolute;
                content: "";
                background-position: center center;
                top: 50%;
                transform: translateY(-50%);
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        .currentProperty {
            &:before {
                height: 3rem;
                width: 2.5rem;
                background-image: inline-image("../../../wwwroot/Static/Images/eo_map_symbol_small.png");
                left: -3rem;
            }
        }

        .forsale {
            &:before {
                height: 3rem;
                width: 2.5rem;
                background-image: inline-image("../../../wwwroot/Static/Images/eo_map_symbol_small_light.png");
                left: -3rem;
            }
        }
    }

    &.list-usp {
        @extend .center;

        > li {
            margin: $base-margin-medium;

            .list-usp__heading {
                text-transform: uppercase;
            }

            .list-usp__info {
                text-transform: none;
                font-size: 2rem;

                &.list-usp__info--important {
                    @extend .underline;
                    display: inline-block;
                    padding-bottom: $base-padding;
                }
            }
        }
    }

    &.list-paging {
        text-align: center;

        > li {
            display: inline-block;
            font-size: 1.7rem;

            &.divider {
                margin: 0 1rem 0 1rem;
            }

            .active {
                font-weight: 600;
            }
        }
    }

    &.list-bullets {
        @extend p;
        margin-top: 0;

        > li {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_arrowright_grey.svg");
            background-size: 1.7rem 1.7rem;
            background-position: center left;
            padding-left: 1.8rem;
            background-repeat: no-repeat;
            background-position-y: 1rem;
            background-position-x: -0.3rem;

            @media screen and (min-width: $screen-sm) {
                background-position-y: 0.5rem;
            }
        }
    }

    &.list-submenu {
        margin: 2rem 0 2rem 0;

        > li {
            display: inline-block;
            margin-right: -4px;
            position: relative;

            a {
                @extend h5;
                display: block;
                padding: 1.4rem 2rem 0 2rem;
                font-weight: 100;
            }

            &.parent {
                a {
                    font-weight: 600;
                }
            }


            &.active {
                span {
                    border-bottom: 1px solid $orange;
                    padding-bottom: 0.3rem;
                    color: $gray-darker;
                }
            }

            &:hover {
                a {
                    color: $gray-semidark;
                }
            }
        }
    }

    &.list-submenu--v2 {
        &.list-submenu--v2__upper {
            margin-bottom: 0;

            .seperator {
                margin-right: 0;
                margin-left: 4px;
            }
        }


        &.list-submenu--v2__lower {
            margin-top: 1rem;

            a {
                padding-top: 0;

                span {
                    font-size: 1.3rem;
                    padding-top: 0;
                }
            }
        }

        li {
            a {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &.list-icons {
        display: inline-block;
        -webkit-transform: perspective(1000px);

        > li {
            display: inline-block;
            margin: 0 $base-margin-medium/5 0 0;

            > a {
                font-size: inherit;
            }
        }

        &.list-icons__center {
            > li {
                margin: 0 $base-margin-medium/10;
            }
        }

        &.full-width {
            width: 100%;
        }

        &.list-icons--list-image {
            > li {
                margin: $base-margin-medium / 2;
                margin-bottom: 0;
            }

            img {
                width: auto;
                height: 4.5rem;
            }
        }
    }

    &.list-medium-icons {
        display: inline-block;
        -webkit-transform: perspective(1000px);

        > li {
            display: inline-block;
            margin: 0 $base-margin-medium/5 0 0;

            > a {
                font-size: inherit;
            }
        }

        &.list-icons__center {
            > li {
                margin: 0 $base-margin-medium/10;
            }
        }

        &.full-width {
            width: 100%;
        }

        &.list-icons--list-image {
            > li {
                margin: $base-margin/2;
            }

            img {
                width: auto;
                height: 10rem;
            }
        }
    }

    &.list-sort {
        padding-top: 0;

        > li {
            display: inline-block;
            margin-right: $base-margin-medium/2;
            margin-top: $base-margin*2;

            @media screen and (min-width: $screen-sm) {
                margin-right: $base-margin-medium;
                margin-top: $base-margin*2;
            }

            padding: $base-margin 0 $base-margin 0;

            &.active, &:hover {
                &:after {
                    margin-right: -50%;
                    width: 100%;
                }
            }

            &.active {
                &:before {
                    position: absolute;
                    top: -0.2rem;
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: $orange;
                }
            }

            position: relative;


            &:after {
                position: absolute;
                bottom: -0.2rem;
                content: "";
                width: 0;
                height: 1px;
                right: 50%;
                background-color: $orange;
                transition: width .5s ease, margin-right .5s ease;
            }


            &:last-child {
                margin-right: 0;
            }

            a {
                @extend h5;
                color: $gray-dark;
                text-decoration: none;

                &.back {
                    &:after {
                        content: "";
                        background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_orange.svg");
                        background-position: center left;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 2rem;
                        background-repeat: no-repeat;
                        transform: rotate(90deg) translateY(150%);
                    }
                }
            }
        }
    }

    &.list-menu {
        > li {
            padding: 1rem 0 0 0;
            font-size: 100%;
        }
    }

    &.list-documents {
        > li {
            display: inline-block;
            vertical-align: top;
            font-size: 1.5rem;
            width: 13rem;
            text-align: center;
            word-break: break-word;

            a {
                font-size: 1.5rem;
                background: inline-image("../../../wwwroot/Static/Images/eo_document.svg") no-repeat top;
                padding-top: 7rem;
                background-size: 8rem;
                display: block;
            }
        }
    }

    &.list-images {
        text-align: center;

        > li {
            margin-bottom: -4px;
            text-align: center;

            > div {
                background-image: inline-image("../../../wwwroot/Static/Images/eo_loader.svg");

                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 11rem 11rem;
                background-color: $gray-light;
                position: relative;
                overflow-y: hidden;
            }

            &.list-images--half {
                @media screen and (min-width: $screen-sm) {
                    display: inline-block;
                    margin-right: -4px;
                    width: 50%;
                }
            }

            .list-images__image-container {
                padding-bottom: (2100/3150)*100%;

                img {
                    width: 100%;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }

                &.list-images__image-container--unsetproportions {
                    padding-bottom: 0;

                    img {
                        position: relative;
                    }
                }
            }

            .list-images__iframe-container {
                padding-bottom: 59%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .list-images__description {
                display: block;
                padding-top: $base-padding;
                padding-bottom: $base-padding *4;
                font-size: $base-fontsize * 1.47;
                line-height: 2.28rem;
                position: relative;
            }
        }
    }

    &.list-with-heading {
        > .list-with-heading__item {
            font-size: $base-fontsize * 2.1;
            line-height: 3rem;

            @media screen and (min-width: $screen-sm) {
                font-size: $base-fontsize * 1.5;
                letter-spacing: 0.03rem;
            }

            margin: 0;
            position: relative;

            &.list-with-heading__item--inactive {
                color: $gray-semidark;
            }

            .list-with-heading__heading {
                padding-right: 12rem;
                display: block;
            }

            .list-with-heading__definition {
                margin-bottom: 2rem;
                display: inline-block;

                @media screen and (max-width: 400px) {
                    margin-bottom: 1rem;
                }
            }

            .list-with-heading__action {
                position: absolute;
                right: 0;
                top: 0;

                &.button {
                    top: 0.5rem;

                    @media screen and (max-width: 400px) {
                        position: absolute;
                        right: 0;
                    }
                }

                @media screen and (max-width: 400px) {
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 2rem;
                }
            }

            .list-with-heading__inverted {
                color: $white;
            }
        }
    }

    &.inverted {
        > li {
            color: $white !important
        }
    }
}

.seperator {
    &.seperator__small {
        font-size: 2rem;
        padding: 0 0.5rem;
    }

    &.seperator__bold {
        font-weight: 600;
    }
}


dl {
    @extend p;
    line-height: normal;
    margin: 0;
    letter-spacing: 0.1rem;

    dt {
        width: 60%;
        display: inline-block;
        font-weight: 500;
        text-align: left;
        margin-bottom: 1.5rem;
    }

    dd {
        text-align: left;
        width: 38%;
        display: inline-block;
    }

    &.right-align-dd {
        dd {
            text-align: right;
        }
    }

    &.fact-list {
        padding: $base-padding*2 0 $base-padding*2 0;

        dt {
            width: 40%;
        }

        dd {
            width: 58%;
        }
    }

    &.tight {
        dt {
            width: 100%;
            margin-bottom: 0.5rem;

            @media screen and (min-width: $screen-xs) {
                width: 29%;
            }
        }

        dd {
            width: 100%;
            margin-bottom: 2rem;

            @media screen and (min-width: $screen-xs) {
                width: 70%;
            }
        }
    }
}

.two-column-list-area {
    margin-top: 2rem;

    h2 {
        width: inherit;
    }

    .list-with-icons {
        margin-top: 2rem;
        margin-bottom: 2rem;
        position: relative;

        li {
            height: 5rem;
            font-size: 1.67rem;
            line-height: 3rem;

            a {
                @include clearfix;
                display: block;
                padding: 1rem;
            }

            &:active, &:hover {
                background-color: $beige-orange;
            }
        }

        .list-with-icons__first-col {
            display: inline-block;
            background-repeat: no-repeat;
            background-position-x: left;
            padding-left: 4rem;

            &.list-with-icons__first-col--apartment {
                background-image: inline-image("../../../wwwroot/Static/Images/eo_apartment_orange.svg");
            }

            &.list-with-icons__first-col--house {
                background-image: inline-image("../../../wwwroot/Static/Images/eo_house_black.svg");
            }
        }

        .list-with-icons__second-col {
            position: absolute;
            right: 2rem;
        }
    }
}


.link-column {
    margin-bottom: 1rem;
}

.list-linkgroup {
    border-top: 1px solid $orange;
    padding-top: $base-padding*1.7;

    @media screen and (min-width: $screen-sm) {
        padding-top: $base-padding*1.4;
    }

    &.list-linkgroup__small {
        li, li a {
            font-size: 1.5rem;
            font-family: $font-raleway;

            @media screen and (min-width: $screen-sm) {
                font-size: 1.3rem;
            }
        }
    }

    li {
        margin-bottom: 1.1rem;

        a {
            font-family: $font-abril-text;
            font-size: 2.3rem;

            @media screen and (min-width: $screen-sm) {
                font-size: 1.9rem;
            }

            &.bold {
                font-family: $font-raleway;
                font-size: 1.5rem;
                font-weight: 700;
                letter-spacing: 0.05rem;

                @media screen and (min-width: $screen-sm) {
                    font-size: 1.3rem;
                }
            }
        }

        &.small-bottom-margin {
            margin-bottom: 0.5rem;
        }

        &:hover {
            a {
                color: $gray-semidark;
            }
        }
    }
}
