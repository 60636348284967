﻿@import "../base/_variables.scss";

#horizontal-menu {
    /*Transition on the nav when scrolled back up to the top*/
    &.sticky-menu {
        transition: background-color 100ms ease-out;
        /*Transition when hovering over the navbar buttons*/
        .menu-item::after {
            content: '';
            display: block;
            transform: scaleX(0);
            transition: transform 300ms ease-in-out;
            width: 100%;
            height: 1px;
            background-color: #FFF;
        }

        .menu-item:hover::after {
            transform: scaleX(1);
        }
    }
    /*Transition on the nav when scrolled down*/
    &.sticky-menu-top {
        box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1);
        transition: background-color 1s ease-in-out;

        .menu-background {
            background-color: $white;
            opacity: 0.95;
        }

        .menu-background-dark {
            background-color: $gray-dark;
            opacity: 0.95;
        }
    }
}
