@import "../base/variables";
@import "../mixins/_clearfix.scss";

ul.searchresult {
    .searchresult__item {
        padding: 4rem 0 4rem 0;
        border-bottom: 1px solid $gray-semilight;
        position: relative;
        @include clearfix;

        .searchresult__item__heading {
            margin-bottom: $base-margin *4;
        }

        .searchresult__item__link {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        .searchresult__item__thumbnail {
            float: left;
            display: block;
            width: 35%;
            min-height: 1px;
            margin-right: $base-margin *4;
            margin-bottom: $base-margin 2;
            margin-top: $base-margin;

            @media screen and (min-width: $screen-md){
                width: 30%;
            }
        }

        .searchresult__item__text {
            margin: 0;
            display: block;

            &.searchresult__item__text--noimage {
                width: 100%;
                padding-left: 0;
            }
        }
    }
}
