@import "_variables.scss";

html {
    font-size: 6.75px;

    @media(min-width: $screen-sm) {
        font-size: 8px;
    }

    @media(min-width: $screen-md) {
        font-size: 9px;
    }

    @media(min-width: $screen-lg) {
        font-size: 9px;
    }
}

body {
    -webkit-font-smoothing: subpixel-antialiased;
    font-family: $font-raleway;
    overflow-x: hidden;
    color: $gray-dark;
    background-color: $white;

    &.active, &.modal-open {
        overflow: hidden;
        overflow-y: hidden !important;
        .introsection {
            display: none;
        }

        .mainpage-heading {
            display: none;
        }

        #back-to-top {
            display: none;
        }

        .always-on-top {
            display: none;
        }

        #property-menu {
            display: none;
        }
    }

    .modal {
        position: fixed;
    }
}

html, body {
    height: 100%;
}

.margin-top {
    &.margin-top--extra-large {
        margin-top: 14rem;
    }

    &.margin-top--large {
        margin-top: 10rem;
    }

    &.margin-top--large-xs {
        @media (max-width: $screen-xs-max) {
            margin-top: 10rem !important;
        }
    }

    &.margin-top--medium-large {
        margin-top: 8rem;
    }

    &.margin-top--medium {
        margin-top: 4rem;
    }

    &.margin-top--medium-xs {
        @media (max-width: $screen-xs-max) {
            margin-top: 4rem;
        }
    }

    &.margin-top--small {
        margin-top: 2rem;
    }

    &.margin-top--small-sm-xs {
        @media (max-width: $screen-sm-max) {
            margin-top: $base-margin-medium / 2;
        }
    }

    &.margin-top--xsmall {
        margin-top: 1rem;
    }

    &.margin-top--tiny {
        margin-top: 0.5rem;
    }

    &.margin-top--none {
        margin-top: 0;
    }

    &.margin-top--horizontal-menu-height {
        margin-top: $horizontal-menu-height-mobile;

        @media screen and (min-width: $screen-sm) {
            margin-top: $horizontal-menu-height-small-tablet;
        }

        @media screen and (min-width: $screen-md) {
            margin-top: $horizontal-menu-height-tablet;
        }

        @media screen and (min-width: $mobile-menu-breakpoint) {
            margin-top: $horizontal-menu-height-small-desktop;
        }

        @media screen and (min-width: $small-desktop-menu-breakpoint) {
            margin-top: $horizontal-menu-height-desktop;
        }
    }

    &.margin-top--mobile {
        @media screen and (max-width: $screen-sm) {
            margin-top: $horizontal-menu-height-mobile;
        }
    }
}

.margin-bottom {
    &.margin-bottom--medium {
        margin-bottom: 4rem;


        &.margin-bottom--medium--md {
            margin-bottom: 0;

            @media (max-width: $screen-md-max) {
                margin-bottom: 4rem;
            }
        }
    }

    &.margin-bottom--medium--md {
        @media (min-width: $screen-md) {
            margin-bottom: 4rem;
        }
    }

    &.margin-bottom--small {
        margin-bottom: 2rem;
    }

    &.margin-bottom--small--sm {
        @media (max-width: $screen-sm-max) {
            margin-bottom: 2rem;
        }
    }

    &.margin-bottom--xsmall {
        margin-bottom: 1rem;
    }

    &.margin-bottom--none {
        margin-bottom: 0;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.hidden {
    display: none;
}

.onlyOnMobileScreen {
    @media all and (min-width: $screen-xs) {
        display: none;
    }
}
.onlyOnfullScreen {
    @media all and (max-width: $screen-xs - 1) {
        display: none;
    }
}
