/* Colors */
$white: #FFF;

.color-white {
    background-color: $white;
}

$gray-dark: #1e1e1e !default;

$validation-color: #ff761a;

$gray: #B6B7B8 !default;

.color-gray {
    background-color: $gray;
}

$gray-darker: #575756 !default;

.color-gray-darker {
    background-color: $gray-darker;
}

$gray-semidark: #9c9c9c !default;

.color-gray-semidark {
    background-color: $gray-semidark;
}

$gray-transparent: rgba(48,48,48,0.85);

.color-gray-transparent {
    background-color: $gray-transparent;
}

$gray-light: #F9F9F9;

.color-gray-light {
    background-color: $gray-light;
}


$gray-semilight: #dddddd;

.color-gray-semilight {
    background-color: $gray-semilight;
}

$gray-light-transparent: rgba(242, 242, 242, 0.95);

.color-gray-light-transparent {
    background-color: $gray-light-transparent;
}

$white-transparent: rgba(255, 255, 255, 0.9);

.color-white-transparent {
    background-color: $white-transparent;
}

$lightgrey-transparent: rgba(241, 242, 242, 0.9);

.color-lightgrey-transparent {
    background-color: $lightgrey-transparent;
}

$orange: #F39200;

.color-orange {
    background-color: $orange;
}

$light-orange: lighten($orange, 25%) !default;

.color-light-orange {
    background-color: $light-orange;
}

$orange-transparent: rgba(243, 146, 0, 0.85);

.color-orange-transparent {
    background-color: $orange-transparent;
}

$beige-orange: #feefdb;

.color-beige-orange {
    background-color: $beige-orange;
}

$black-transparent: rgba(0,0,0,0.85);

.color-black-transparent {
    background-color: $black-transparent;
}

$black-transparent-seethrough: rgba(0,0,0,0.45);

.color-black-transparent-medium {
    background-color: $black-transparent-seethrough;
}

$green: #00A651;
$red: #ED1C24;

/* Fonts */
$base-fontsize: 1rem !default;
$base-padding: 1rem !default;
$base-padding-large: 10rem;
$base-padding-superlarge: 18rem;
$base-margin: 0.5rem;
$base-margin-small: 2rem;
$base-margin-medium: 4rem;
$base-margin-large: 6rem;

$font-raleway: 'Raleway', sans-serif;
$font-abril-text: 'AbrilText', Georgia;
$font-abril-text-italic: 'AbrilText-Italic', Georgia;


$gutter-padding: 7rem;
$gutter-padding-mobile: 4rem;

$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Medium-large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-mlg: 1200px !default;
$screen-mlg-min: $screen-mlg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-mlg-desktop: $screen-mlg-min !default;

$screen-lg: 1500px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-mlg-min - 1) !default;
$screen-mlg-max: ($screen-lg-min - 1) !default;

$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;

$grid-float-breakpoint: $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


$container-tablet: (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md: $container-desktop !default;

// Medium-Large screen / semi wide desktop
$container-mediumlarge-desktop: (1170px + $grid-gutter-width) !default;
//** For `$screen-mlg-min` and up to lg
$container-mlg: $container-mediumlarge-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1470px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;

$inner-container: 700px;
$inner-container-large: 1100px;

$nav-button-left-position: 17px;

$horizontal-menu-height-desktop: $base-fontsize * 9.3;
$horizontal-menu-height-small-desktop: $base-fontsize * 8.5;
$horizontal-menu-height-tablet: $base-fontsize * 8;
$horizontal-menu-height-small-tablet: $base-fontsize * 9;
$horizontal-menu-height-mobile: $base-fontsize * 10;

$small-desktop-menu-breakpoint: 1300px;
$mobile-menu-breakpoint: 1130px;
