@import "../base/_variables.scss";

.start-page {
    &.top {
        z-index: 1;
        background-repeat: no-repeat;
        background-color: $beige-orange;
        height: 100vh;
        position: relative;

        video {
            width: 100vw;
            height: 100vh;
            object-fit: cover;
            pointer-events: none;
        }

        .see-video-link {
            position: absolute;
            bottom: 80px;
            color: #fff;
            left: 50%;
            transform: translate(-50%);
            font-size: 2rem;
            z-index: 1;

            @media screen and (min-width: $screen-md) {
                font-size: 1.7rem;
                bottom: 40px;
            }
        }

        .introsection {
            position: absolute;
            top: 0;
            padding-top: 30vh;

            a.button {
                border: none;
                color: $orange;
                background: $white;
                margin-bottom: 3rem;
                transition: color .2s, background-color .2s;

                width: 32rem;
                padding: 1.3rem 1.4rem;
                font-size: 1.8rem;
                font-weight: 500;

                &:hover {
                    color: $white;
                    background-color: $orange !important; // Alla buttons har redan en grå bakgrund på hover som är important, så denna måste bli ännu mer important..
                    border: none !important;
                }
            }

            .introsection__heading {
                position: static;
                text-align: center;
                letter-spacing: 3px !important;
                width: 100%;
                margin-bottom: 2rem;
                font-size: 1.1rem;

                @media screen and (min-width: $screen-sm) {
                    display: block;
                    letter-spacing: 5px !important;
                    font-size: 1.8rem;
                }
            }


            .introsection__subheading {
                font-size: 6.0rem;
                
                @media screen and (min-width: $screen-sm) {
                    font-size: 9.8rem;
                }

                > span {
                    display: block;
                }
            }
        }
    }
}