@import "../base/_variables.scss";
// Grid system
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
    @include container-fixed;

    @media (min-width: $screen-sm-min) {
        width: $container-sm;
    }

    @media (min-width: $screen-md-min) {
        width: $container-md;
    }

    @media (min-width: $screen-mlg-min) {
        width: $container-mlg;
    }

    @media (min-width: $screen-lg-min) {
        width: $container-lg;
    }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
    @include container-fixed;
}

// Row
//
// Rows contain and clear the floats of your columns.

.row {
    @include make-row;
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;

// Columns, offsets, pushes, and pulls 5 different breakpoints

@include make-grid(xs);

@media (min-width: $screen-sm-min) {
    @include make-grid(sm);
}

@media (min-width: $screen-md-min) {
    @include make-grid(md);
}

@media (min-width: $screen-mlg-min) {
    @include make-grid(mlg);
}

@media (min-width: $screen-lg-min) {
    @include make-grid(lg);
}

.no-padding {
    padding: 0 !important;
}

.no-padding--xs {
    @media (max-width: $screen-xs-max) {
        padding: 0 !important;
    }
}

.no-margin {
    margin: 0 !important;
}

*, *:before, *:after {
    box-sizing: border-box;
}

.col-transparent-black {
    background-color: rgba(0, 0, 0, 0.49);
}

.col-light {
    color: white;
}

.bottom {
    position: absolute;
    bottom: 1rem;
    left: 50%;
}

.main-area {
    max-width: $inner-container;
    margin: 0 auto;
    min-height: 4rem;
    @include clearfix;

    &.overflow {
        overflow: auto;
    }

    &.main-area--wide {
        max-width: $inner-container-large;
    }
}

.full-area--orangebeige {
    background-color: $beige-orange;
    position: relative;
}

.full-area--dark {
    background-color: $gray-darker;
    position: relative;
}

.full-area--grey {
    background-color: $gray-light;
    position: relative;
}

.row--centered {
    text-align: center;
}

.col--centered {
    display: inline-block;
    float: none;
    margin-right: -4px;
    vertical-align: text-top;
}
