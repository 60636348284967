@import "../base/_variables.scss";

input.property-autocomplete-input {
  width: 100%;
  padding: $base-padding $base-padding $base-padding 5rem;
  margin-bottom: $base-padding;
  font-size: 16px;
  border-bottom: 1px solid $gray-semilight;
  color: $gray-dark;
  background-image: inline-image("../../../wwwroot/Static/Images/eo_search.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: 4rem 4rem;
}

.property-autocomplete-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: $base-padding * 2;
  background-color: $lightgrey-transparent;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1000;

  &.loading {
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-image: inline-image("../../../wwwroot/Static/Images/eo_loader.svg");
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      background-size: contain;
      background-color: $lightgrey-transparent;
      z-index: 1001;
    }
  }

  li {
    padding: $base-padding;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.2rem;
    margin: 0;
    font-size: 1.5rem;
    user-select: none;
    -webkit-user-select: none;

    &.selected {
      text-decoration: line-through;
      cursor: default;
    }

    &:hover, &.active {
      font-weight: bold;
    }
  }
}

.property-autocomplete-selections {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  column-gap: $base-margin;
  row-gap: $base-margin;

  li {
    padding: $base-padding;
    background: $gray-light;

    span {
      cursor: pointer;
      font-family: "Times New Roman", serif;
      font-size: 2.2rem;
      color: $gray-semidark;

      &:hover {
        color: $gray-dark;
      }
    }
  }
}

.error-message {
  color: $red;
  margin: $base-margin-large;
}
