﻿@import "../base/_variables.scss";

// 2020-03-11 This one seems to be obsolete, the only class using
// it seems to itself be unused these days.
@mixin menu-animation-right($action, $animationtime) {
    @if $action == "open" {
        animation: slide $animationtime forwards;
    }
    @else if $action == "close" {
        animation: return-slide $animationtime forwards;
    }
}
