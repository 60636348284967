@import "../base/_variables.scss";

.EPiServerForms .Form__Description {
  margin-bottom: 1rem
}

.EPiServerForms .FormImageSubmitButton {
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  background: none;
  box-shadow: none
}

.EPiServerForms .Form__Status .Form__Status__Message {
  display: block;
  padding: 1rem 0;
  margin: 1rem 0
}

.EPiServerForms .Form__Status .Form__Warning__Message {
  color: $red
}

.EPiServerForms .Form__Status .Form__Readonly__Message {
  display: block;
  padding: 1rem;
  margin: 1rem 0;
  color: $red;
}

.EPiServerForms .Form__Status .hide {
  display: none
}

.EPiServerForms .Form__Success .Form__SubmissionSuccess__Message {
  display: block;
  padding: 1rem 0;
  margin: 1rem 0
}

.EPiServerForms .Form__Success .Form__Success__Message {
  color: #333
}

.EPiServerForms .Form__Success .hide {
  display: none
}

.EPiServerForms .Form__NavigationBar .Form__NavigationBar__Action {
  display: inline-block;
  padding: inherit;
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgba(41,128,189,0.5);
  border-radius: 4px;
  font-size: inherit;
  height: 2em;
  width: 10em;
  max-width: 10rem;
  color: initial;
  background-image: linear-gradient(to top, #fff, #e6e6e6);
  background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
  background-image: -ms-linear-gradient(top, #fff, #e6e6e6);
  background-image: -webkit-linear-gradient(top, #fff, #e6e6e6)
}

.EPiServerForms .Form__NavigationBar .Form__NavigationBar__Action:disabled {
  cursor: not-allowed;
  opacity: .4
}

.EPiServerForms .Form__NavigationBar .Form__NavigationBar__ProgressBar {
  display: inline-block;
  border: 1px solid rgba(41,128,189,0.5);
  vertical-align: middle;
  width: 40%
}

.EPiServerForms .Form__NavigationBar .Form__NavigationBar__ProgressBar .Form__NavigationBar__ProgressBar--Progress {
  background-color: rgba(41,128,189,0.5);
  width: 0%;
  height: 1rem
}

.EPiServerForms .Form__NavigationBar .Form__NavigationBar__ProgressBar .Form__NavigationBar__ProgressBar--Text {
  display: none
}

.EPiServerForms .FormStep .FormStep__Description {
  margin-bottom: 1rem
}

.EPiServerForms .Form__Element {
  margin: 0 0 2rem 0
}

.EPiServerForms .Form__Element .Form__Element__Caption {
  display: inline-block;
  min-width: 15%;
  vertical-align: top
}

.EPiServerForms .Form__Element .Form__Element__ValidationError {
  color: $red;
  display: block;
  word-wrap: break-word
}

.EPiServerForms .ValidationFail {
  color: $red
}

.EPiServerForms .ValidationFail input,.EPiServerForms .ValidationFail textarea,.EPiServerForms .ValidationFail select {
  border-color: $red
}

.EPiServerForms .ValidationFail legend {
  color: $red
}

.EPiServerForms .hide {
  display: none
}

.EPiServerForms .visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap
}

.EPiServerForms .FormSubmitButton:focus {
  outline: 3px dashed rgba(41,128,189,0.5)
}

.EPiServerForms .FormResetButton:focus {
  outline: 3px dashed rgba(41,128,189,0.5)
}

.EPiServerForms .FormCaptcha .FormCaptcha__Refresh {
  font-size: 1em;
  width: auto;
  height: auto
}

.EPiServerForms .FormRange .FormRange__Wrapper .FormRange__Output__Wrapper {
  position: relative;
  display: block;
  text-align: center
}

.EPiServerForms .EditView__InvisibleElement.FormStep {
  display: block;
  border-radius: 4px;
  border-color: rgba(184,192,197,0.6);
  background-color: rgba(184,192,197,0.6);
  color: #000;
  padding: .2rem;
  box-shadow: 3px 3px 5px #ccc
}

.EPiServerForms .EditView__InvisibleElement.FormStep .FormStep__Warning {
  color: $red;
  display: block;
  word-wrap: break-word
}

.EPiServerForms .EditView__InvisibleElement.FormHidden {
  display: inline-block;
  border-radius: 4px;
  border: 1.5px outset rgba(184,192,197,0.6);
  padding: .2rem 1rem;
  min-width: 18rem
}

.EPiServerForms .Warning {
  color: $red;
  display: block;
  word-wrap: break-word
}

.EPiServerForms .Form__Warning {
  color: $red;
  display: block;
  word-wrap: break-word
}

.EPiServerForms .Form__Original__ParagraphText {
  display: none
}
