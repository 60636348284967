@import "../base/variables";

.mainpage-heading-container {
    height: 100%;
    width: 100%;
    position: relative;

    .mainpage-heading {
        display: block;
        position: absolute;
        cursor: default;
        left: $gutter-padding;
        top: 18.5%;

        @media screen and (min-width: $screen-sm) {
            left: 17.5rem;
        }

        > h1 {
            border-top: 2px solid $orange;
            margin-top: 2rem;
            display: inline-block;
            padding-top: $base-padding *2;
            line-height: 2rem;
        }

        img {
            display: block;
            width: 21.5rem;
        }
    }
}

.introsection {
    z-index: 1;
    text-align: center;
    color: $white;
    width: 100%;
    height: 100vh;
    padding-top: 16rem;

    &.introsection--dark {
        color: $gray-dark;
    }

    &.introsection--alternate {
        padding-top: 20rem;

        @media (min-width: $screen-sm) {
            padding-top: 30rem;
        }
    }

    &.introsection--middle {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
    }

    @media (min-width: $screen-sm) {
        padding-top: 20rem;
        height: auto;
    }

    .introsection__heading {
        position: absolute;
        bottom: 3rem;
        text-align: center;
        width: 100%;

        @media (min-width: $screen-sm) {
            position: initial;
        }

        margin-bottom: 2rem;
    }

    .introsection__logo {
        bottom: 3rem;
        text-align: center;
        margin-bottom: 2rem;

        > img {
            width: 50%;
        }
    }

    .introsection__subheading {
        margin-bottom: 4.5rem;

        > span {
            display: block;

            @media (min-width: $screen-sm-min) {
                display: inline;
            }
        }
    }

    .introsection__buttons {
        a {
            width: 25rem;
            margin: 0 auto;
            margin-bottom: 1rem;
            display: block;

            @media (min-width: $screen-sm) {
                display: inline-block;
                margin-bottom: 0;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }
    }
}

.property-container {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    background-color: $gray-light;
    word-break: break-word;
    position: relative;
    color: $white;

    .property-container--link {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;

        &.property-container--link--colored {
            background-color: $black-transparent;
            opacity: 0.8;
        }
    }

    &.clickable {
        cursor: pointer;
    }

    &.active, &:hover {
        .property-container__project-overlay {
            opacity: 1;

            &.property-container__project-overlay--inverted {
                opacity: 0.4;
            }
        }
    }

    .property-container__project-overlay {
        transition: visibility 1s ease, opacity 1s ease;
        opacity: 0.8;
        position: absolute;
        background-color: rgba(0,0,0,0.7);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &.property-container__project-overlay--inverted {
            opacity: 0.8;
        }
    }

    .property-container__puff-container {
        position: absolute;
        right: 5rem;
        top: 5rem;

        &.property-container__puff-container--on-top-image {
            left: calc(50% - 7rem);
            top: calc(50% - 7rem);
            z-index: 1;
        }

        @media (min-width: $screen-sm) {
            top: 7rem;
            right: 7rem;
        }
    }

    .property-container__tag {
        display: block;
        margin-bottom: 2rem;

        > span {
            padding: 0.75rem 2.5rem;
            background-color: $gray-light-transparent;
            color: $gray-dark;
        }
    }

    .property-container__content-container {
        position: absolute;
        left: $gutter-padding-mobile;
        top: 30%;

        @media (min-width: $screen-sm) {
            left: $gutter-padding;
            top: 40%;
        }
    }

    .property-container__content-container--top-left {
        position: absolute;
        left: $gutter-padding-mobile;
        z-index: 999;
        top: $gutter-padding-mobile;

        @media (min-width: $screen-sm) {
            left: $gutter-padding;
            top: 12rem;
        }
    }

    .property-container__content-container--bottom-left {
        position: absolute;
        left: $gutter-padding-mobile;
        z-index: 999;

        @media (min-width: $screen-sm) {
            left: $gutter-padding;
        }

        bottom: 7%;
    }

    .property-container__content-container--bottom-right {
        position: absolute;
        right: $gutter-padding-mobile;
        z-index: 999;

        @media (min-width: $screen-sm) {
            right: $gutter-padding;
        }

        bottom: 7%;
    }

    &.property-listing {
        padding-bottom: 100%;

        &.property-listing__evenout {
            padding-bottom: 50%;
        }

        > div {
            background-size: cover;
            background-position: center;
        }

        .property-container__puff-container {
            top: 4rem;
            right: 4rem;
        }

        .property-container__content-container {
            top: 40%;
        }

        h5 {
            padding-bottom: 1rem;
        }

        h2 {
            padding-top: 1rem;
        }
    }

    &.property-container__low {
        padding-bottom: (600/1025)*100%;

        @media (min-width: $screen-md) {
            padding-bottom: (384/1025)*100%;
        }

        > div {
            background-size: cover;
            background-position: center;
        }
    }

    &.full-width {
        padding-bottom: (576/1025)*100%;

        .property-container__content-container {
            top: 25%;

            @media (min-width: $screen-sm) {
                top: 35%;
            }
        }
    }

    &.nyprod-project {
        //Box with aspect ratio 16:9
        padding-bottom: (576/1025)*100%;


        @media (max-width: $screen-sm) {
            //Box as square
            padding-bottom: 100%;
        }

        > div {
            @media (max-width: $screen-sm) {
                background-size: cover;
            }
        }
    }

    &.half-width {
        padding-bottom: (1200/1146)*100%;

        @media (min-width: $screen-sm) {
            padding-bottom: ((1200/1146)/2)*100%;
        }
    }

    > div {
        background-repeat: no-repeat;
        background-size: 100%;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    h5 {

        @extend .underline;
        padding-bottom: $base-padding * 1.5;
        display: inline-block;

        &.newproduction {
            border: none;
            text-transform: none;
            font-weight: 100;
            font-size: 2.5rem;
            margin-top: 0.8rem;

            @media (min-width: $screen-sm) {
                margin-top: 2rem;
            }
        }
    }

    h6 {
        &.newproduction {
            @extend .underline;
            padding-bottom: $base-padding * 1.5;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    h2 {
        padding-top: $base-padding;
    }

    &.loading {
        .loader {
            display: block;
        }

        .loader2 {
            display: block;
        }

        .arrow-down {
            display: none;
        }
    }

    .loader {
        position: absolute;
        left: -10px;
        bottom: 0;
        display: none;
    }

    .loader2 {
        position: absolute;
        bottom: 0;
        display: none;
        margin-left: auto;
        margin-right: auto;
        transform: translateX(-50%);
    }

    .property-container__clickarea {
        position: absolute;
        bottom: $gutter-padding;
    }

    .arrow-down {
        position: absolute;
        bottom: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        transform: translateX(-50%);
    }
}

#property-menu-container {
    height: 60px;

    @media (min-width: $screen-sm) {
        height: 70px;
    }

    @media (min-width: $screen-md) {
        height: 76px;
    }

    @media (min-width: $screen-lg) {
        height: 82px;
    }
}

.property-menu {
    height: 60px;

    @media (min-width: $screen-sm) {
        height: 70px;
    }

    @media (min-width: $screen-md) {
        height: 76px;
    }

    @media (min-width: $screen-lg) {
        height: 82px;
    }

    position: fixed;
    bottom: 0;
    z-index: 10;
    background: $white;
    box-shadow: rgba(0,0,0,0.35) 0 0 2px 0;

    &.static {
        position: static;
        margin-top: 1px;
    }

    ul {
        table-layout: fixed;
        height: 100%;

        li {
            vertical-align: middle;
            display: table-cell;
            background-size: 3rem 3rem;
            background-repeat: no-repeat;
            background-position: bottom 4.5rem center;
            position: relative;

            a {
                position: absolute;
                @extend .button-text;
                display: inline-block;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                font-size: $base-fontsize*1.4;
                letter-spacing: 0;

                @media (min-width: $screen-sm) {
                    font-size: $base-fontsize*1.7;
                    letter-spacing: $base-fontsize*0.1;
                }

                span {
                    display: block;
                    bottom: 1.5rem;
                    left: 50%;
                    position: absolute;
                    transform: translateX(-50%);
                }
            }
        }

        li.summary {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_document.svg");
        }

        li.images {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_camera.svg");
        }

        li.estateSelector {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_bovaljare.svg");
        }

        li.floorplan {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_planritning.svg");
        }

        li.facts {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_pencil.svg");
        }

        li.map {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_map.svg");
        }

        li.documents {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_pdf.svg");
        }

        li:hover {
            @media (min-width: $screen-md) {
                background-color: $beige-orange;
            }
        }

        li.active {
            background-color: $beige-orange;
        }
    }
}

h1.property-heading {
    @extend h2;
    letter-spacing: 0;
    margin-top: 0.5rem;
    padding-top: 1rem;
    color: $gray-dark;
}

.property-summary {
    .property-summary__section {
        width: 100%;
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        float: left;

        &.side-section {
            @media (min-width: $screen-sm) {
                width: 50%;
            }

            @media (min-width: $screen-md) {
                width: 41.66%;
            }

            @media (min-width: $screen-lg) {
                width: 26%;
            }
        }

        &.middle-section {
            @media (min-width: $screen-sm) {
                width: 50%;
            }

            @media (min-width: $screen-md) {
                width: 58.33%;
            }

            @media (min-width: $screen-lg) {
                width: 47%;
            }
        }
    }

    .property-summary__address-price {
        @media screen and (min-width: $screen-xs) {
            margin-bottom: 6rem;
        }
    }

    .property-summary__share-container {
        padding-top: 10rem;

        li {
            margin: 0 1rem 0 0 !important;
        }
    }

    .property-summary__container {
        padding: 2rem 0;
    }

    .property-summary__container--middle {
        margin-bottom: $base-margin-medium;

        @media screen and (min-width: $screen-md) {
            padding-left: $base-margin-medium;
            margin-top: 0;
        }

        @media screen and (min-width: $screen-lg) {
            padding-right: $base-margin-medium;
        }
    }

    .property-summary__tag {
        display: block;

        > span {
            padding: 0.75rem 2.5rem;
            background-color: $orange;
            color: $white;
            font-size: 2rem;
        }
    }
}

.extended-fact-toggler {
    color: $orange;
    display: inline-block;
    cursor: pointer;
    position: relative;

    &:before {
        content: "\1F6C8";
        font-size: 20px;
        position: absolute;
        bottom: -7px;
    }
}

.extended-fact {
    margin-bottom: 1.5rem;
    display: none;

    &.active {
        display: block;
    }
}

.property-facts {
    margin-bottom: 3rem;

    span {
        display: block;
    }

    .fact-heading {
        font-size: $base-fontsize * 2.2;
        margin-top: 2rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.18rem;

        @media screen and (min-width: $screen-sm) {
            font-size: $base-fontsize * 1.6;
        }

        &:nth-child(1) {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .fact {
        font-size: $base-fontsize * 2.1;
        color: $gray-dark;
        line-height: 3.6rem;
        letter-spacing: 0.03rem;

        > a {
            font-size: $base-fontsize * 2.1;
            font-weight: 600;
        }

        @media screen and (min-width: $screen-sm) {
            font-size: $base-fontsize * 1.5;
            line-height: 2.8rem;

            > a {
                font-size: $base-fontsize * 1.5;
                font-weight: 600;
            }
        }
    }

    @media screen and (max-width: $screen-sm) {
        .fact-section {
            .fact-heading {
                &:nth-child(1) {
                    margin-top: 2rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .fact-section:nth-child(1) {
            .fact-heading {
                &:nth-child(1) {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.project-main-container {
    word-break: break-word;

    .project-main-content {
        @media screen and (min-width: $screen-lg) {
            padding: 0 4rem;
        }
    }

    .button-half {
        @media screen and (max-width: $screen-xs) {
            width: 100%;
        }
    }

    .facts-producer-logo {
        img {
            max-height: 120px;

            @media screen and (max-width: $screen-md) {
                max-height: 100px;
            }

            @media screen and (max-width: $screen-xs) {
                max-height: 80px;
            }
        }
    }
}

.back-to-top {
    position: fixed;
    z-index: 100;
    opacity: 0;
    right: 2.7rem;
    bottom: 2.6rem;
    width: 30px;
    height: 30px;
    visibility: hidden;
    transform: translateY(-50%);
    transition: opacity 0.5s, visibility 0.5s;

    @media screen and (min-width: $screen-sm) {
        right: 1.7rem;
        bottom: 1.6rem;
        width: 32px;
        height: 32px;
    }

    @media screen and (min-width: $screen-md) {
        width: 38px;
        height: 38px;
    }

    @media screen and (min-width: $screen-lg) {
        width: 40px;
        height: 40px;
    }

    &.back-to-top--large-bottom-margin {
        bottom: 11.4rem;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }

    > a {
        background: inline-image("../../../wwwroot/Static/Images/eo_top.svg") no-repeat center;
        display: block;
        height: 100%;
        width: 100%;
        text-indent: -9999px;
    }
}

.intro-text {
    padding: $base-padding-large 0;
    position: relative;

    &.intro-text--watermark {
        background-size: 95%;

        @media screen and (min-width: $screen-sm) {
            background-size: 87%;
        }

        @media screen and (min-width: $screen-md) {
            background-size: 80%;
        }

        @media screen and (min-width: $screen-lg) {
            background-size: 55%;
        }
    }

    &.intro-text--centered {
        @extend .center;
    }

    .list-sort {
        padding-top: $base-padding;
    }
}

p {
    &.intro-text__underline {
        height: 0;
    }
}


.intro-text__underline {
    @extend .underline;
    width: 8rem;
    margin: 2rem auto 0 auto;
}


.article {
    .article-preamble {
        &__italic {
            text-align: center;
            font-family: $font-abril-text-italic;
            margin: 0 5rem 0 5rem;
        }
    }

    &.article-right {
        padding: 3rem 0 10rem 0;
    }

    &.article-right__gray {
        background-color: $gray-light-transparent;
    }

    .article-right__heading {
        margin-bottom: 2rem;
        @extend .small;
        @extend .center;
    }

    hr {
        border: 0;
        height: 0;
        border-top: 3px solid $orange;
        margin-bottom: 3rem;
    }

    .thankyou-response-holder {
        padding-bottom: 0;
    }

    .for-article {
        padding: 15px !important;
        background-color: #FEEFDB;
        min-height: 0px;

        .simplecontact-form {
            margin-top: 20px;
        }

        .special-setting {
            margin: 0;
        }
    }

    .newsletterform {
        padding: 15px
    }
}

.related-content-section {
    .related-content-heading {
        margin: 2rem 0 2rem 0;
        @extend .underline;
        @extend .small;
    }
}


.text--narrow {
    padding: 0 15px;
    max-width: 580px;
    margin: 3rem auto
}

.close-button {
    position: absolute;
    width: 3.5rem;
    height: 3.5rem;
    right: 2.6rem;
    top: 2.6rem;
    background-image: inline-image("../../../wwwroot/Static/Images/eo_cross.svg");
    background-repeat: no-repeat;
    background-size: contain;

    &.close-button--inverted {
        background-image: inline-image("../../../wwwroot/Static/Images/eo_cross_inverted.svg");
    }

    &.close-button--center {
        left: 50%;
        margin-left: -1.2rem;
    }

    &.close-button--small-margins {
        transform: translateY(50%);
    }
}

.slider-menu-right {
    overflow-y: auto;
    position: fixed;
    height: 100%;
    right: -100%;
    background-color: $black-transparent;
    color: $white;
    top: 0;
    width: 100%;
    z-index: 9999;

    @media screen and (min-width: $screen-sm) {
        width: 50%;
    }

    @media screen and (min-width: $screen-md) {
        width: 30%;
        max-width: 500px;
    }

    .form-container {
        position: relative;
    }

    &.active {
        right: -100%;
        @include menu-animation-right("open", 0.5s);
    }

    &.inactive {
        right: 0px;
        @include menu-animation-right("close", 0.5s);

        @media screen and (min-width: $screen-sm) {
            @include menu-animation-right("close", 1s);
        }

        @media screen and (min-width: $screen-md) {
            @include menu-animation-right("close", 1.5s);
        }
    }

    @keyframes slide {
        100% {
            right: 0;
        }
    }

    @keyframes return-slide {
        100% {
            right: -100%;
        }
    }
}

.xform {
    .loader {
        display: none;
    }

    .loader2 {
        margin-left: auto;
        margin-right: auto;
    }

    &.xform--sending {
        .loader {
            display: block;
        }

        .loader2 {
            display: block;
        }
    }
}
// Project description
.info-section {
    color: $gray-dark;

    .info-section__info {
        margin: 1rem 0 $base-margin-medium 0;
    }

    &.info-section--inverted {
        color: $white;

        p {
            color: $white;
        }
    }
}

.view-more-container {
    @extend .center;
    margin-top: $base-margin-medium;
    margin-bottom: $base-margin-medium;

    &.small-margins {
        margin-top: 1rem;
        margin-bottom: 6rem;
    }

    .view-more-container__heading {
        margin: 0 0 $base-margin*10 0;

        i {
            display: inline-block;
        }
    }

    .view-more-container__share {
        margin-bottom: $base-margin-medium / 2;
    }

    .view-more-container__input {
        margin-top: $base-margin-medium;
    }

    .center {
        @media (max-width: $screen-sm) {
            display: inline-block;

            a {
                display: inline-block;
                width: 100%;
            }
        }
    }
}

.broker-container {
    h2 {
        color: $gray-dark;

        &.colored {
            color: $orange;
        }
    }
}

.broker-welcome-section {
    background-position: 75% !important;

    &.broker {
        padding: 4rem 6rem;

        .broker__content {
            position: relative;
            padding-top: 0;

            .broker__content__image {
                height: 50rem;
                margin-left: -6rem;
                max-width: none;
            }
        }
    }

    @media (max-width: $screen-sm) {
        background-image: none !important;
    }

    .broker-welcome-section__usp {
        position: absolute;
        left: 17rem;
        top: 30%;

        @media (min-width: $screen-sm) {
            right: 2rem;
            left: auto;
            top: 30%;
        }
    }
}

.usp-box {
    color: $white;
    background-color: $orange-transparent;
    text-align: center;
    font-size: 5px;
    padding: 2em;
    width: 28em;
    height: 28em;

    @media (min-width: $screen-sm) {
        font-size: 10px;
    }

    .usp-box__content {
        @include vertical-align();

        .usp-box__content__logo {
            text-align: center;
            position: relative;
            margin-bottom: 2em;

            img {
                width: 14em;
            }

            &:after {
                position: absolute;
                bottom: -1rem;
                right: 50%;
                margin-right: -3rem;
                content: "";
                width: 6rem;
                height: 1px;
                background-color: $white;
            }
        }

        .usp-box__content__text {
            font-size: 4.5em;
            margin-bottom: 0.5em;
        }

        .usp-box__content__byline {
            font-family: $font-abril-text-italic;
            color: white;
            font-size: 1.8em;
        }
    }
}

.broker {
    text-align: center;

    @media (min-width: $screen-md) {
        padding: 0;
    }

    &.broker__img-wrapper {
        max-height: 50rem;
        min-height: 450px;
        min-width: 300px;
        margin-left: -80px;
        margin-right: 80px;

        @media (min-width: $screen-md) {
            height: 50rem;
            text-align: left;
        }

        > img {
            max-width: unset;
            width: unset;
            height: unset;
        }
    }

    &.broker__img {
        height: 37rem;
        display: block;
        margin: 0 auto;
    }

    &.broker--padding {
        padding: $base-padding * 2;
    }

    &.broker--background-image {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
    }

    &.broker--hover {
        cursor: pointer;
    }

    &.broker__contactdetails-wrapper {
        text-align: left !important;

        @media (min-width: $screen-md) {
            padding-left: 20px;
        }
    }

    .broker__content {
        word-break: normal;
        text-align: left !important;
        padding-top: 3rem;

        @media (min-width: $screen-sm) {
            display: block;
        }

        .broker__content__name {
            font-family: $font-abril-text;
        }

        .broker__content__name--colored {
            font-family: $font-abril-text-italic;
            color: $orange;
        }

        .broker__content__area {
            display: block;
            padding-top: 1.5rem;
        }

        &.broker__content--center {
            display: inline-block;
        }
    }

    .broker__link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

.broker_contactform {
    @media (min-width: $screen-md) {
        position: absolute;
        bottom: 0;
    }
}

.broker__sales {
    padding: 15px 0 15px 0;
    text-align: center;
}

.toggle-section {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    position: relative;
    border-bottom: 1px solid $orange;
    margin: $base-margin*2 0 $base-margin*4 0;
    padding-bottom: $base-padding;
    text-transform: uppercase;
    cursor: pointer;

    &.toggle-section--first-in-section {
        margin-top: 0;
    }

    .arrow-down {
        position: absolute;
        right: 0.5rem;
    }

    &.active {
        .arrow-down {
            top: 1rem;

            &:after {
                transform: rotate(225deg);
            }
        }
    }
}

.image-container {
    position: relative;
    color: $white;
    text-align: center;

    &.full-width {
        padding-bottom: 40rem;

        @media (min-width: $screen-sm) {
            padding-bottom: (605/1281)*100%;
        }
    }

    h2 {
        margin-top: 1rem;
    }

    h5 {
        @extend .underline;
        padding-bottom: $base-padding;
        display: inline-block;
    }

    > div {
        @media (min-width: $screen-xs) {
            background-size: 100%;
        }

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        position: absolute;

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 100%;
        }

        > p {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            margin-bottom: 0;
        }
    }
}

.map-container {
    position: relative;
    text-align: center;

    &.full-width {
        padding-bottom: (12/12)*100%;

        @media (min-width: $screen-xs) {
            padding-bottom: (11/19)*100%;
        }

        @media (min-width: $screen-md) {
            padding-bottom: (7/19)*100%;
        }
    }

    &.map-container--low {
        padding-bottom: (12/18)*100%;

        @media (min-width: $screen-xs) {
            padding-bottom: (12/26)*100%;
        }

        @media (min-width: $screen-md) {
            padding-bottom: (9/32)*100%;
        }

        @media (min-width: $screen-mlg) {
            padding-bottom: (7/32)*100%;
        }
    }

    > .google-map {
        background-repeat: no-repeat;
        background-size: 100%;
        height: 100%;
        width: 100%;
        position: absolute;

        > .map-overlay {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            position: relative;

            > button {
                position: absolute;
                top: 45%;
                z-index: 1;
            }

            &.hidden {
                display: none;
            }

            &:after {
                content: "";
                background-image: url('/Static/Images/eo_stockholm_map_background.webp');
                background-repeat: no-repeat;
                background-size: cover;
                filter: blur(4px);
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
        }
    }
}

.map-description {
    padding: 2rem;
    text-align: center;
}

.property-summary__container, #brokers {
    .contact-information {
        padding-top: 0;
        margin-bottom: 2rem;
    }
}

.contact-information {

    &.contact-information--small-padding {
        padding: 1rem;
    }

    &.contact-information--bottom-with-margin {
        @media (min-width: $screen-md) {
            position: absolute;
            bottom: 3rem;
            width: 100%;
        }
    }

    &.contact-information--white-centered {
        .contact-information__item {
            a {
                color: white;
                position: relative;
            }
        }
    }

    .contact-information__item {
        position: relative;
        margin-top: 1rem;
        height: 2rem;
        white-space: nowrap;

        a {
            position: absolute;
            top: -0.5rem;
            @extend p;
            margin: 0;
            text-decoration: none;
            color: $gray-dark;
            line-height: 3rem;
            z-index: 2;

            &.phone {
                &:before {
                    content: "T. ";
                    font-weight: 600;
                }
            }

            &.email {
                &:before {
                    content: "E. ";
                    font-weight: 600;
                }
            }
        }

        .prefix {
            font-weight: 600;
            padding-right: 0.5rem;
        }
    }
}
/* Footer */
#fullpage {
    #footer {
        > .full-area--orangebeige {
            z-index: 0;
        }
    }
}

#footer {
    > .full-area--orangebeige {
        z-index: -1;
    }

    .footer-section {
        margin-bottom: $base-margin*4;
    }

    .footer__logo {
        width: 20rem;
        padding-bottom: 0;
        margin-top: -1.5rem;

        @media screen and (min-width: $screen-sm) {
            width: 16rem;
        }
    }

    .footer__content {
        padding: $base-padding*2 0;
        max-width: $inner-container-large;
        margin: auto auto;

        &.footer__content--underlined {
            padding-top: 7.5rem;
            padding-bottom: $base-padding *2;
            @extend .underline;
        }

        p {
            @extend p.small;

            &.copyright {
                display: inline;
            }

            .unstyled-small {
                font-weight: 400;
            }
        }

        .footer__uppercase-link {
            @extend h5.small;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 2.5rem;
        }

        h5.small {
            padding-bottom: 0;
            margin-bottom: 1rem;

            > a {
                font-size: inherit;

                &:after {
                    display: none;
                }
            }

            &.heading-without-link {
                @extend h4.mini;
                text-transform: none;
                font-size: 2.3rem;
                padding-bottom: 0.4rem;

                @media screen and (min-width: $screen-sm) {
                    font-size: 2rem;
                }
            }
        }
    }

    .footer__bottom {
        margin-top: 3rem;

        p.small, a.small {
            @extend h5.small;
            font-size: 1.4rem;
            font-weight: 500;

            @media screen and (min-width: $screen-sm) {
                font-size: 1.2rem;
            }
        }
    }

    .clearboth-mobile {
        clear: both;
        float: right;

        @media screen and (min-width: $screen-md) {
            clear: none;
            float: left;
        }
    }
}

.icon {
    display: inline-block;
    width: 28px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    transform: scale(0.75);
    transition: transform 0.2s;

    &.icon-email {
        background-image: inline-image("../../../wwwroot/Static/Images/eo_letter.svg");

        &.light {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_letter_white.svg");
        }
    }

    &.icon-facebook {
        width: 10px;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_facebook.svg");

        &.light {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_facebook_white.svg");
        }
    }

    &.icon-instagram {
        width: 27px;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_instagram.svg");

        &.light {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_instagram_white.svg");
        }
    }

    &.icon-linkedin {
        width: 27px;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_linkedin.svg");

        &.light {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_linkedin_white.svg");
        }
    }

    &.icon-mynewsdesk {
        width: 27px;

        &.light {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_mynewsdesk_white.svg");
        }
    }

    &.icon-twitter {
        width: 27px;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_twitter.svg");

        &.light {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_twitter_white.svg");
        }
    }

    &.icon-messenger {
        width: 27px;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_messenger.svg");

        &.light {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_messenger_white.svg");
        }
    }

    &.icon-search {
        background-image: inline-image("../../../wwwroot/Static/Images/eo_search.svg");
    }

    &.icon-info {
        width: 27px;
        height: 27px;
        background-image: inline-image("../../../wwwroot/Static/Images/eo_icon_info.svg");
    }

    &.icon-enlarge {
        &:hover {
            transform: scale(1);
        }
    }
}

.hero-image-container {
    padding-bottom: (600/1600)*100%;
    overflow: hidden;

    @media screen and (min-width: $screen-xs) {
        padding-bottom: (600/1600)*100%;
    }

    @media screen and (min-width: $screen-sm) {
        padding-bottom: (600/1600)*100%;
    }

    background-color: $gray-light;
    position: relative;

    .hero-image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
    }
}

.broker-wrapper {
    position: relative;

    .broker-wrapper__link {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
    }

    .broker-item .broker-item__image {
        min-height: 300px;
    }

    &:hover {
        .broker-name-container__heading {
            &:after {
                margin-right: -3rem;
                width: 6rem;
                background-color: $orange;
            }
        }
    }
}

.broker-name-container {
    padding: 4rem 0 2rem 0;

    .broker-name-container__heading {
        position: relative;

        &:after {
            position: absolute;
            bottom: -2rem;
            content: "";
            width: 0;
            height: 0.2rem;
            right: 50%;
            background-color: $orange;
            transition: width .5s ease, margin-right .5s ease;
        }
    }
}

.broker-contactdetails-container {
    height: 20rem;
    padding: 4rem 0 4rem 0;

    .broker-name-container__heading {
        position: relative;

        &:after {
            position: absolute;
            bottom: -2rem;
            content: "";
            width: 0;
            height: 0.2rem;
            right: 50%;
            background-color: $orange;
            transition: width .5s ease, margin-right .5s ease;
        }
    }
}

.broker-tile-contactdetails-container {
    margin: 0.5rem 0 1rem 0;

    .contactdetail {
        padding: 0.5rem;

        > a {
            display: inline-block;
        }

        > .broker-title {
            font-size: 1.7rem;
            display: inline-block;
            line-height: 2rem;
        }
    }
}

.broker-item {
    padding: 4rem 4rem 0 4rem;
    background-color: $gray-light;
    position: relative;

    .broker-item__image {
        height: 33rem;
        width: auto;
        margin-bottom: -2px;
        max-width: none;
    }
}

.broker-image-container {
    padding-top: 3rem;

    .broker-image-container__image-link {
        margin: 1rem 0 2rem 0;
        display: block;
    }
}

.broker-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6rem;
}

.return-button {
    &.return-button--broker {
        position: static;
    }

    .return-button__background {
        position: absolute;
        width: 30px;
        height: 30px;
        left: $nav-button-left-position;
        background: inline-image("../../../wwwroot/Static/Images/eo_left.svg") no-repeat center;
        transform: translateY(-50%);

        @media screen and (min-width: $screen-sm) {
            width: 32px;
            height: 32px;
        }

        @media screen and (min-width: $screen-md) {
            width: 38px;
            height: 38px;
        }

        @media screen and (min-width: $screen-lg) {
            width: 40px;
            height: 40px;
        }

        &.return-button--broker__background {
            top: 30%;
            left: 64px;
        }
    }

    .return-button__text {
        @extend h5.small;
        white-space: nowrap;
        position: absolute;
        left: 8.6rem;
        text-transform: uppercase;

        &.vertical-center {
            top: 50%;
            transform: translateY(-50%);
        }

        &.return-button--broker__text {
            top: 108%;

            @media screen and (min-width: $screen-sm) {
                left: 16rem;
                top: 48%;
            }
        }
    }
}

.filter-section {
    position: relative;
    padding-top: 4rem;
    padding-bottom: 4rem;

    .filter-selection {
        margin-bottom: 1rem;

        select {
            padding-right: 3.5rem;
            cursor: pointer;
        }

        @media screen and (min-width: $screen-md) {
            margin-bottom: 0;
        }
    }

    .filter-section__info-text--narrow {
        position: absolute;
        bottom: -2.5rem;
    }

    .filter-section__info-text {
        bottom: 45%;
        left: $gutter-padding;
        position: absolute;
    }

    .filter-section__extra-filter {
        position: absolute;
        right: $gutter-padding;

        &.filter-section__extra-filter--right {
            position: static;
            float: right;
        }
    }

    .badges-container {
        margin-top: -4rem;

        > li {
            margin-top: 1rem;
            margin-right: 1rem;
        }
    }
}


.propertylisting-container {
    position: relative;
    min-height: 20rem;

    &.propertylisting-container--nohits {
        min-height: 6rem;
    }

    .loader {
        margin: 5rem;
    }

    .loader2 {
        margin: 5rem;
    }
}

.property-listing {
    .property-listing__heading {
        margin-top: -4rem;
        margin-bottom: 4rem;
    }
}

.stamp {
    height: 14rem;
    width: 14rem;
}

.puff {
    text-align: center;
    border: 1px solid $white;
    height: 14rem;
    width: 14rem;
    border-radius: 50%;
    color: $white;
    vertical-align: middle;
    display: table-cell;
    padding: 1rem;

    @media (min-width: $screen-sm) {
        height: 16rem;
        width: 16rem;
    }

    p {
        margin: 0;
        line-height: 1.3;
    }

    &.puff--small {
        height: 14rem;
        width: 14rem;

        > p {
            font-size: 2.3rem;
        }
    }

    > p {
        font-size: 2.5rem;
        font-family: $font-abril-text;
        color: $white;
        padding: 0;

        @media (max-width: $screen-sm) {
            font-size: 2.2rem;
        }

        &.small {
            font-size: 2.3rem;
        }

        &.italic {
            font-family: $font-abril-text-italic;
            font-size: 1.8rem;

            @media (min-width: $screen-sm) {

                &.italic {
                    font-size: 2.8rem;
                }
            }
        }

        &.large {
            font-size: 3.5rem;

            @media (min-width: $screen-sm) {
                &.large {
                    font-size: 5.5rem;
                }
            }
        }

        &:last-child {
            position: relative;

            &:after {
                position: absolute;
                bottom: -1rem;
                right: 50%;
                margin-right: -1rem;
                content: "";
                width: 2rem;
                height: 1px;
                background-color: $white;

                @media (min-width: $screen-sm) {
                    bottom: -1.5rem;
                }
            }
        }
    }
}

.document-container {
    @extend .center;
    margin-bottom: $base-margin-large;
}


.squarepuff {
    @extend .center;
    display: block;
    padding: $base-margin-medium;

    &.squarepuff--small-padding {
        padding: $base-margin * 3;
    }

    &.squarepuff--dark {
        background-color: $black-transparent-seethrough;
    }

    .squarepuff__description {
        @extend .button-text;
        color: $white;
    }

    .squarepuff__text {
        padding-top: 0;

        &.squarepuff__text--small {
            font-size: $base-fontsize*3;
            margin-top: -0.5rem;
        }

        .squarepuff__text__white {
            color: $white;
        }

        .squarepuff__text__orange {
            color: $orange;
            font-family: $font-abril-text-italic;
        }
    }
}

.fuzzy-peach {
    background-image: url('/Static/Images/eo_colored_background.webp');
    background-size: cover;
    padding: 2rem 0;
}

.map-background {
    background-image: url('/Static/Images/eo_stockholm_map_background.webp');
    background-size: cover;
    padding: 14rem 0;
}

.lightgray-background {
    background-color: $lightgrey-transparent;
    padding: 14rem 0;
}

.office {
    h4 {
        margin-bottom: 5rem;
    }

    &.office__container {
        margin-bottom: 6rem;

        img {
            width: 100%;
        }
    }

    &.office__info {
        text-align: left;
        @include clearfix;
        height: 15rem;
    }
}

.office-block {
    display: inline-flex;

    .office-block__image-container {
        display: inline-block;

        img {
            width: 400px;
            height: 300px;
            object-fit: cover;
        }
    }

    .office-block__information-container {
        padding-left: 2rem;
        display: inline-block;

        .office-information {
            text-align: left;
            word-wrap: break-word;

            .office-information__title {
                font-size: $base-fontsize * 2;
                font-weight: 500;
            }
        }
    }

    @media screen and (max-width: $screen-sm) {
        display: block;

        .office-block__image-container {
            width: 100%;
            margin-bottom: 2rem;

            img {
                width: 100%;
            }
        }

        .office-block__information-container {
            width: 100%;
            padding-left: 0;

            .office-information {
                .office-information__title {
                    font-size: $base-fontsize * 3;
                }
            }
        }
    }
}

.article_listing_page {
    .right {
        float: right;
    }

    .invisible {
        display: none;
    }
}

.article_listing {
    .article-tile_title {
        @extend h1.mini;

        a {
            font-size: inherit
        }
    }

    &.article_listing__info {
        margin-top: 1.5rem;
        text-align: left;
        @include clearfix;
        height: 10rem;

        @media screen and (max-width: $screen-sm) {
            height: 8rem;
        }
    }
}

.article_listing_image {
    .image {
        padding-top: 65%;
        background-size: cover;
    }
}

.occasion-tile {

    &.occasion-tile__content {
        margin-top: 1.5rem;
        background-color: $gray-light;
        padding: 4rem;
        border-left: 0.4rem solid $green;

        &.occasion-tile__content--available {
            border-left-color: $green;
        }

        &.occasion-tile__content--few {
            border-left-color: $orange;
        }

        &.occasion-tile__content--none {
            border-left-color: $red;
        }
    }

    &.occasion-tile__button {
        @extend .button;
        @extend .button-blacktext;
        @extend .button-blacktext--transparent;
        margin: 1.5rem auto;
        padding: 1rem 2rem;
    }

    &.occasion-tile__availability {
        @extend h5;
        @extend .small;
        text-transform: none;
        display: block;
        color: $orange;
    }

    &.occasion-tile__availability--few:before {
        color: $orange;
        content: "F\00E5tal kvar";
    }

    &.occasion-tile__availability--free:before {
        color: $red;
        content: "Fullbokat";
    }
}

.occasion-info {
    margin-top: 4rem;

    li {
        @extend h5;
        @extend .small;
        border-bottom: 1px solid $green;
        margin: auto 2rem;
        text-transform: none;
    }

    .available {
        border-bottom-color: $green;
    }

    .few {
        border-bottom-color: $orange;
    }

    .none {
        border-bottom-color: $red;
    }
}

.sortsection-withanchor {
    position: relative;

    .sortsection-withanchor__anchor {
        position: absolute;
        top: -12rem;

        @media screen and (min-width: $screen-sm) {
            top: -10rem;
        }
    }
}

.preamble {
    position: relative;

    .preamble__share {
        position: absolute;
        left: -17rem;
        top: 0.5rem;

        @media screen and (min-width: $screen-lg) {
            left: -20rem;
        }
    }
}

.map-popup {
    padding: $base-padding;

    .map-popup--metaheading {
        margin-bottom: 0.5rem;
    }

    .map-popup--heading {
        color: $orange;
        margin-bottom: 0.5rem;
    }

    .map-popup--info {
        margin-bottom: 0.5rem;
        margin-top: 0;
    }

    .map-popup--action {
        margin: 0;

        > a {
            text-transform: uppercase;
        }
    }
}

#search-overlay {
    margin: 0 auto;
    max-width: 400px;
}

.autocomplete {
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.2rem;
    margin: 0 auto;
    max-width: 400px;

    .autocomplete__heading {
        display: block;
        margin-top: 2rem;
        font-size: 14px;
        margin-bottom: 1rem;
        color: $white;

        &:first-child {
            margin-top: 2rem;
        }
    }

    .autocomplete__suggestion {
        margin-bottom: 1rem;
        line-height: 1.4;

        &.active {
            a {
                color: $white;
            }
        }
    }

    a {
        color: $gray;
        font-size: 14px;

        &:hover, &:focus, &:active {
            color: $white;
        }
    }
}

.link-list-block {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: transparent;

    h4 {
        margin-bottom: 4rem;
    }
}

.gray-section {
    .link-list-block {
        @extend .color-gray-light;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.dark-section {
    .form-dark {
        input:not([type='button']), textarea, select, input[type='submit'] {
            border-color: $white;
        }

        input[type='submit'] {
            border-color: $orange;
        }

        select {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_dropdown_arrow_white.svg");
        }
    }

    .info-section {
        @extend .info-section--inverted;
    }
}



.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.cookie-acceptance {
    font-size: 1.5rem;
    font-family: $font-raleway;
    position: fixed;
    min-height: 0;
    left: 0;
    bottom: 0;
    background-color: $white-transparent;
    width: 100%;
    box-shadow: rgba(0,0,0,0.35) 0 0 2px 0;

    .cookie-acceptance--inner {
        text-align: center;
        @extend .main-area;
        min-height: 0;
        margin-bottom: 2rem;

        .cookie-acceptance__heading {
            margin: 2rem 0;
            display: block;
        }
    }
}

.flip-container {
    perspective: 1000;
    transform-style: preserve-3d;

    &.flip {
        .back {
            transform: rotateY(0deg);
        }

        .front {
            transform: rotateY(180deg);
        }
    }

    .flipper {
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;
        height: 750px;
    }

    .front, .back {
        backface-visibility: hidden;
        transition: 0.6s;
        transform-style: preserve-3d;
        position: absolute;
        top: 0;
        left: 0;
    }

    .front {
        transform: rotateY(0deg);
        z-index: 2;
    }

    .back {
        transform: rotateY(-180deg);
    }
}

.rating-container {
    overflow: auto;

    .rating-container__logo {
        width: 12rem;
        float: right;
    }

    .rating-count {
        font-size: $base-fontsize * 1.6;
        font-weight: normal;

        @media screen and (min-width: $screen-sm) {
            font-size: $base-fontsize * 1.4;
        }
    }

    .rating-mean {
        font-size: $base-fontsize * 2.2;
        font-weight: 600;
    }

    .rating-heading {
        //To give space for star icon
        margin-left: 20px;
    }

    .rating-star {
        height: 15px;
    }

    .readMore {
        display: none;
    }
}

.alert {
    border: 1px solid $orange;
    background-color: $beige-orange;
    color: $gray-dark;
    font-family: $font-abril-text-italic;
    padding: $base-padding;
    text-transform: none;
    font-size: 2rem;

    &.alert-info {
        .alert--description {
            color: $gray-dark;
            float: left;
        }

        .alert--value {
            color: $orange;
            float: right;
        }
    }
}

.twogrid-norender {
    @media screen and (min-width: $screen-sm) and (max-width: $screen-mlg) {
        display: none;
    }
}

.threegrid-norender {
    @media screen and (min-width: $screen-mlg) {
        display: none;
    }
}

.twogrid-render {
    @extend .col-sm-6;

    .property-listing__evenout {
        padding-bottom: 100% !important;
    }
}

.threegrid-render-twocols {
    @extend .col-mlg-8;

    .property-listing__evenout {
        @media screen and (min-width: $screen-mlg) {
            padding-bottom: 50% !important;
        }
    }
}

.threegrid-render-onecol {
    @extend .col-mlg-4;

    .property-listing__evenout {
        @media screen and (min-width: $screen-mlg) {
            padding-bottom: 100% !important;
        }
    }
}

.info-block {
    color: $gray-dark;
    text-align: center;

    > h2 {
        font-size: 12rem;
        color: $orange;
        margin-bottom: 2rem;
    }
}

.top {
    z-index: 1;
    background-repeat: no-repeat;
    background-color: $beige-orange;
    height: 100vh;
    background-attachment: fixed;
    background-size: cover;
    position: relative;

    &.display-on-top {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 99999999;
        left: 0;
        top: 0;
    }

    .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: $black-transparent;
        opacity: 0.8;

        &.light {
            background-color: $white-transparent;
        }
    }
}

.video-container {
    position: relative;
    padding-bottom: (9/16)*100%;
    background-color: $gray-light;

    iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}

.fullscreen-bg {
    z-index: 1;
    background-repeat: no-repeat;
    background-color: #feefdb;
    height: 100vh;
    min-height: 105rem;
    background-attachment: fixed;
    background-size: cover;
    background-size: cover;
    position: relative;
    overflow: hidden;

    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0, 0.7); //TODO: should be a variable if needed?
    }

    .background {
        position: absolute;
        bottom: 0px;
        right: 0px;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        transform: translateX(-50%);
        left: 50%;
    }

    .full-video {
    }

    .full-image {
        max-width: none;
    }
}

.is-touch {
    .full-video {
        display: none !important;
    }
}

.no-touch {
    .full-video {
        display: block !important;
    }
}

.area-background {
    background-image: url(/Static/Images/eo_background_areas.webp);
    background-position: center center;
}

.full-image {
    display: block;
    width: 100%;
}

.full-image-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.popup {
    background-color: white;
    z-index: 2;
    position: absolute;
    padding: $base-padding;
    text-align: center;
    bottom: 0;
    border: 1px solid $orange;
    max-width: 100%;

    .popup__heading {
        color: $gray-dark;
        word-break: break-word;
    }

    input {
        color: black !important;
        border-color: $orange !important;
    }

    &.popup--fixed-max-width {
        max-width: 40rem;
    }

    &.popup--info {
        @extend p.small;
        color: #000000;
        bottom: inherit;
        text-align: left;
        width: 100%;
        left: 0px;
        margin-top: 1rem;
        display: block;

        span {
            width: 80%;
            display: block;
        }

        a {
            width: 2rem;
            height: 2rem;
            background-image: inline-image("../../../wwwroot/Static/Images/eo_cross.svg");
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
    }
}

.loader2 {
    height: 8rem;
    width: 8rem;
    background-color: transparent;
    overflow: hidden;
    display: none;

    .stripe {
        margin-left: -3rem;
        margin-bottom: 0.5rem;
        height: 2rem;
        width: 14rem;
        animation: flipstripes 2s infinite;
        transform: rotate(-225deg);
        transform: translate3d(0,0,0);

        .stripe-background {
            width: 100%;
            background-color: $orange;
            animation: expandstripes 2s infinite;
            -webkit-backface-visibility: hidden;
            -webkit-transform-style: preserve-3d;
        }
    }

    @keyframes expandstripes {
        0% {
            height: 0;
            opacity: 1;
        }

        25% {
            height: 90%;
        }

        48% {
            height: 100%;
        }

        75% {
            opacity: 1;
            height: 10%;
        }

        95% {
            height: 2%;
            opacity: 0;
        }

        100% {
            height: 0;
        }
    }

    @keyframes flipstripes {
        0% {
            transform: rotate(-225deg);
        }

        49.9% {
            transform: rotate(-225deg);
        }

        50% {
            transform: rotate(45deg);
        }

        99.9% {
            transform: rotate(45deg);
        }

        100% {
            transform: rotate(-225deg);
        }
    }
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid $gray-semidark;
    margin-bottom: 3rem;
}

.tabs {
    width: 100%;
    margin-bottom: 1rem;

    .tab {
        @extend .button-text;
        width: 50%;
        border: 1px solid white;
        border-top: none;
        padding: 1.45rem 1.5rem;
        text-align: center;
        color: white;
        padding: 10px;
        display: inline-block;

        &.active {
            border-bottom: none;
            border-top: 1px solid white;
        }

        &:hover {
            cursor: pointer;
            color: gray;
        }

        &.tab-left {
            margin-right: -4px;
            border-left: none;

            &.active {
                border-left: 1px solid white;
            }
        }

        &.tab-right {
            border-left: none;
            border-right: none;

            &.active {
                border-right: 1px solid white;
            }
        }
    }
}

.winner-mark {
    background-image: inline-image("../../../wwwroot/Static/Images/eo_star.svg");
    background-repeat: no-repeat;
    background-size: 4rem 4rem;
    background-position-x: 0;
    background-position-y: center;
    padding: 1rem 0rem 1rem 5rem;
    margin: 0 0 2rem 0;
}

.housing-coverage {
    .housing-coverage--overlay {
        background-color: $black-transparent;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 100%;
        width: 100%;
        position: absolute;

        .close-button {

            &:hover {
                cursor: pointer;
            }
        }

        input[type='email'] {
            color: $gray-dark;
        }

        &.no-background {
            background: none;
        }
    }

    .map-container {
        &.full-width {
            padding-bottom: (25/12)*100%;

            @media (min-width: $screen-sm) {
                padding-bottom: (8/12)*100%;
            }

            @media (min-width: $screen-md) {
                padding-bottom: (7/12)*100%;
            }

            @media (min-width: $screen-lg) {
                padding-bottom: (5/12)*100%;
            }
        }

        .small-polygon-warning {
            position: absolute;
            color: $orange;
            font-size: 1.7rem;
            font-weight: 500;
        }
    }

    &.draw-mode {
        cursor: url("../../Images/eo_ob_pencil.png") 0 25, default;

        .button-draw {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_cross.svg");
        }
    }

    .housing-coverage-outer-content {
        max-width: 650px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .search-holder {
        .autocomplete-results {
            position: absolute;
            width: 100%;
            z-index: 10;
            padding: 2rem;
            background-color: $lightgrey-transparent;

            > li {
                text-align: left;
                text-transform: uppercase;
                font-weight: 400;
                letter-spacing: 0.2rem;
                margin: 0;
                padding: 0.4rem;
                font-size: 1.5rem;
                user-select: none;
                -webkit-user-select: none;

                &.selected {
                    font-weight: 600;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .search-filter {
        position: relative;
        right: 0;

        > select {
            margin: 0 2rem;
        }
    }

    .housing-coverage-map-buttons {
        position: absolute;
        right: 3rem;
        top: 3rem;

        > a {
            margin-left: 2rem;
        }
    }

    .housing-coverage-map-filter {
        background-color: $white-transparent;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 2rem;

        .input-search {
            border-color: $gray-semidark;
        }

        .search-filter {
            position: static;

            select, a, input {
                margin-top: 3rem;
            }
        }
    }
}

.two-column-block {
    .content {
        margin-top: 1rem;

        :first-child {
            margin-top: 0 !important;
        }
    }

    .extra-left-padding {
        @media screen and (min-width: $screen-mlg) {
            padding-left: 4em;
        }

        @media screen and (min-width: $screen-lg) {
            padding-left: 6em;
        }
    }
}

.ob-campaign {
    padding-bottom: 4rem;
    background-color: $white !important;

    .campaign-wrapper {
        position: relative;
        text-align: center;
        display: inline-block;

        img {
            width: 100%;
        }

        .campaign-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.select2-dropdown {
    display: none !important;

    &.show-results {
        display: inline-block !important;
    }
}

.width-85percent-xs {
    @media screen and (max-width: $screen-sm) {
        width: 85%
    }
}

#more_properties {
    display: none;
}

.vl {
    border-left: 1px solid gray;
    height: 100%;
    position: absolute;
}

#clockdiv {
    font-family: sans-serif;
    color: #fff;
    display: inline-block;
    font-weight: 100;
    text-align: center;
    font-size: 50px;
    position: relative;
}

#clockdiv > div {
    border-radius: 3px;
    display: inline-block;
}

#clockdiv div > span {
    padding: 0 15px 10px 15px;
    border-radius: 3px;
    display: inline-block;
}

.smalltext {
    font-size: 1.3rem;
    color: #d0d6e0;
    letter-spacing: 0.18rem
}


.block-container-image__left-narrow {
    padding: 0;

    .image-block-image {
        @extend .col-narrow;
        @extend .imagecol-left;
    }

    .image-block-text {
        @extend .col-wide;
        @extend .textcol-right;

        h2 {
            margin-bottom: 2rem;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }
}


.block-container-image__left-wide {
    padding: 0;

    .image-block-image {
        @extend .col-wide;
        @extend .imagecol-left;
    }

    .image-block-text {
        @extend .col-narrow;
        @extend .textcol-right;

        h2 {
            margin-bottom: 2rem;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }
}

.block-container-image__right-narrow {
    padding: 0;

    .image-block-image {
        @extend .col-narrow;
        @extend .imagecol-right;
    }

    .image-block-text {
        @extend .col-wide;
        @extend .textcol-left;

        h2 {
            margin-bottom: 2rem;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }
}

.block-container-image__right-wide {
    padding: 0;

    .image-block-image {
        @extend .col-wide;
        @extend .imagecol-right;
    }

    .image-block-text {
        @extend .col-narrow;
        @extend .textcol-left;

        h2 {
            margin-bottom: 2rem;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }
}

.block-container-image__ {
    padding: 0;

    .image-block-text {

        h2 {
            margin-bottom: 2rem;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }
}

.imagecol-right {
    float: right;
    padding-left: 1.5rem;
}

.imagecol-left {
    float: left;
    padding-right: 1rem;
}

.textcol-left {
    float: left;
    padding-right: 2rem;
}

.textcol-right {
    float: right;
    padding-left: 1.5rem;
}

.col-wide {
    width: 66%;
}

.col-narrow {
    width: 34%;
}


.image-block-column {
    padding-top: 1rem;
    padding-bottom: 1rem;

    img {
        width: 100%;
    }

    @media screen and (max-width: $screen-sm) {
        float: none !important;
        width: 100% !important;
        padding-left: 0 !important;
    }
}

.image-block-text {
    p {
        margin-top: 0;
    }
}

.image-byline {
    margin-bottom: 1rem;
    font-size: smaller;
}

.errspan {
    float: right;
    margin-right: 6px;
    margin-top: -28px;
    position: relative;
    z-index: 2;
}

#footerbutton.button:hover {
    border-color: orange !important;
}

.newsletter-footer-width {
    @media screen and (min-width: $screen-md) {
        width: 25rem
    }

    @media screen and (min-width: $mobile-menu-breakpoint) {
        width: 30rem
    }

    @media screen and (min-width: $screen-lg) {
        width: 40rem
    }
}

.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.area-banner {
    width: 100%;
    background: $gray-transparent;
    padding: 2rem;
    text-align: center;
}

.teaser-link-block {
    float: left;
    margin-bottom: $base-margin-medium;
    min-width: 340px;
    @extend .col-lg-3;
    @extend .col-md-4;
    @extend .col-sm-6;
    @extend .col-xs-12;
    height: 160px;

    &.brick--medium {
        height: 320px;

        .brick__body {
            width: 50%;
        }

        .brick__button {
            right: 2.5rem;
            transform: none;
        }
    }

    &.brick--large {
        height: 640px;

        .brick__body {
            width: 50%;
        }
    }

    .teaser__header {
        position: absolute;
    }

    .teaser__body {
        position: absolute;

        p {
            color: $white;
            font-size: 2rem;
            line-height: 3rem;
            margin-top: 1rem;
        }

        &.hidden {
            p {
                display: none;
            }
        }

        width: 100%;
    }

    .teaser__button {
        position: absolute;
        bottom: 2.5rem;
        right: 50%;
        transform: translateX(50%);
    }

    .teaser__inner {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 2.5rem;
        word-break: break-word;
        background-color: $black-transparent;
        transition: background-color 0.5s ease;

        .teaser__inner-overlay {
            opacity: 0;
            transition: opacity 0.5s ease;
            position: absolute;
            background-color: rgba(0,0,0,0.3);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .teaser__inner-heading {
            margin-right: 2.5rem;
            position: absolute;
        }

        &:hover {
            background-color: $gray-darker;

            h2 {
                transition: color 0.5s ease;
                color: $white;
            }

            h1 {
                transition: color 0.5s ease;
                color: $white;
            }

            .teaser__body p {
                display: block;
            }
        }

        &.imageVisible {
            background-size: cover;
            background-position: center top;

            &:hover {
                .teaser__inner-overlay {
                    opacity: 1;
                }
            }
        }

        &.teaser__inner--orange {
            background-color: $orange;

            &:hover {
                background-color: $beige-orange;
            }
        }

        &.teaser__inner--grey {
            background-color: $gray-light;

            &:hover {
                background-color: $light-orange;
            }
        }

        &.teaser__inner--header {
            background-color: $gray-light;

            h1 {
                > span {
                    color: $gray-dark;
                    word-break: normal;
                    display: block;

                    &.orange {
                        color: orange;
                        margin-top: 3rem;
                    }
                }
            }
        }
    }
}

.image-row-container {
    margin-bottom: 10rem;

    .image-row {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        > div {
            flex-grow: 1;
            width: 100%;

            @media (min-width: $container-tablet) {
                width: (100%/2);
            }

            @media (min-width: $container-desktop) {
                width: (100%/4);
            }

            @media (min-width: $container-large-desktop) {
                width: (100%/6);
            }

            > .image-overlay-column {

                &.active, &:hover {
                    .image-overlay-background {
                        opacity: 1;
                    }
                }

                > a {
                    cursor: pointer;

                    > div {
                        word-break: break-word;
                        position: relative;
                        padding-bottom: 100%;
                        height: 40rem;

                        > .image-overlay-background {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            transition: visibility 1s ease, opacity 1s ease;
                            opacity: 0.4;
                            background-color: rgba(0,0,0,0.8);
                        }

                        > .image-overlay-text {
                            position: absolute;
                            margin-left: $gutter-padding-mobile;
                            margin-right: $gutter-padding-mobile;
                            top: 30%;

                            > p {
                                margin-top: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.area-summaries {

    > .area-summaries-desktop {
        tr {
            &.header {
                border-bottom: 1px solid $gray-dark;
            }

            th {
                width: 20%;
                text-align: left;

                &:last-child {
                    text-align: left;
                }
            }

            td {
                padding: $base-padding 0;
                width: 20%;
                text-align: left;

                &:last-child {
                    text-align: left;
                }
            }
        }
    }

    > .area-summaries-mobile {
        display: none;
    }

    @media (max-width: $container-tablet) {

        > .area-summaries-desktop {
            display: none;
        }

        .area-summaries-mobile {
            display: inline-block;
            width: 100%;

            > .area {
                padding-bottom: 1rem;

                .area-header {
                    font-size: $base-fontsize * 2.2;
                }

                .area-information {
                    border-bottom: 1px solid $gray-dark;
                }
            }
        }
    }
}

.land-summaries {
    > table {
        table-layout: fixed;
        white-space: normal;

        tr {
            &.header {
                border-bottom: 1px solid $gray-dark;
            }

            th {
                width: 20%;
                padding: $base-padding 0;
                text-align: left;

                &:last-child {
                    width: 80%;
                    text-align: left;
                }
            }

            td {
                width: 20%;
                padding: $base-padding 0;
                text-align: left;

                &:last-child {
                    width: 80%;
                    text-align: left;
                }
            }
        }
    }

    @media (max-width: $screen-xs) {
        > table {

            tr {
                th {
                    width: 50%;

                    &:last-child {
                        width: 50%;
                    }
                }

                td {
                    width: 50%;

                    &:last-child {
                        width: 50%;
                    }
                }
            }
        }
    }
}

.building-information {
    float: left;

    > h4 {
        position: relative;
        margin-bottom: 4rem;
    }

    > h4:after {
        position: absolute;
        bottom: -2rem;
        left: 0;
        content: "";
        width: 6rem;
        height: 1px;
        background-color: $orange;
    }
}

.indent {
    padding-left: ceil(($grid-gutter-width / 2));
}

.estimated-earnings {
    text-align: center;

    .estimated-earnings-title {
        margin-bottom: 6rem;
    }

    .estimated-earnings-result {
        margin-top: 2rem;
        margin-bottom: 10rem;
        display: flex;
        align-items: flex-end;
        gap: 5%;

        .estimated-earnings-box-container {
            width: 30%;

            .estimated-earnings-label {
                font-size: 1.5rem;
                color: $gray-dark;
                display: block;
                height: 3rem;
            }

            .estimated-earnings-box {
                margin-top: 1rem;
                height: 80px;
                line-height: 80px;
                background: transparent;
                color: $gray-dark;
                font-size: 2.5rem;
                font-weight: 600;
                text-align: center;
                border-radius: 8px;
            }

            input.estimated-earnings-box {
                background: $gray-semilight;
                font-size: 2rem;
                font-weight: 400;
            }
        }

        @media screen and (max-width: $screen-sm) {
            display: block;

            .estimated-earnings-box-container {
                width: 100%;
                margin-bottom: 2rem;

                .estimated-earnings-label {
                    font-size: 2rem;
                }

                .estimated-earnings-box {
                    font-size: 3rem;
                }

                input.estimated-earnings-box {
                    font-size: 2.5rem;
                }
            }

            .estimated-earnings-box-container:first-child {
                width: 100%;
                margin-bottom: 8rem;
            }
        }
    }

    .estimated-earnings-info-container {
        position: relative;
        width: 100%;
        height: 20px;

        .estimated-earnings-slider-info {
            position: absolute;
            top: -7rem;
            left: 0;
            -webkit-animation: bounce .8s ease infinite alternate;

            &.fade-out {
                -webkit-animation: fade-out .8s ease forwards alternate;
            }

            span {
                position: absolute;
                top: 0;
                left: 4px;
            }

            span::after {
                display: block;
                content: "\2193";
                font-size: 2rem;
            }

            @media screen and (max-width: $screen-sm) {
                top: -9rem;

                span::after {
                    font-size: 2.5rem;
                }
            }
        }

        @-webkit-keyframes fade-out {
            0% {
                transform: translateY(0px);
            }

            100% {
                transform: translateY(-10px);
                opacity: 0;
            }
        }

        @-webkit-keyframes bounce {
            0% {
                transform: translateY(0px);
            }

            100% {
                transform: translateY(-10px);
            }
        }
    }

    .estimated-earnings-slider-container {
        position: relative;
        width: 100%;
        height: 50px;

        .ticks {
            display: flex;
            justify-content: space-between;
            padding: 6px 0;

            .tick {
                position: relative;
                display: flex;
                justify-content: center;
                width: 1px;
                background: $gray-semidark;
                color: $gray-semidark;
                height: 8px;
                line-height: 60px;

                &.long {
                    height: 16px;
                }
            }

            @media screen and (max-width: $screen-sm) {
                .tick {
                    display: none;

                    &.long {
                        display: flex;
                    }
                }
            }
        }

        .estimated-earnings-slider-icon {
            position: absolute;
            left: 0;
            top: -6rem;
            cursor: ew-resize;
            width: 64px;
            height: 64px;

            .icon-slider-connection-top {
                position: absolute;
                left: 30px;
                top: 42px;
                height: 5px;
                width: 5px;
                border-radius: 100%;
                background: $gray-semidark;
            }

            .icon-slider-connection {
                position: absolute;
                left: 32px;
                top: 42px;
                height: 22px;
                width: 1px;
                background: $gray-semidark;
            }

            @media screen and (max-width: $screen-md) {
                top: -7rem;
            }

            @media screen and (max-width: $screen-sm) {
                top: -8rem;
            }
        }

        input[type="range"] {
            -webkit-appearance: none;
            height: 4px;
            margin: 0;
            background: $gray-semidark;
            border-radius: 8px;
            background-size: 0% 100%;
            background-repeat: no-repeat;
            pointer-events: none;
        }
        //Using mixins because each major browser needs a separate selector to style parts of the range input, combining selectors would invalidate the whole selector on each browser
        @mixin slider-thumb {
            -webkit-appearance: none;
            cursor: pointer;
            opacity: 0; //If we set display: none we cannot click the range to move the value
        }

        @mixin slider-range {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }

        input[type=range]::-webkit-slider-runnable-track {
            @include slider-range();
        }

        input[type=range]::-moz-range-track {
            @include slider-range();
        }

        input[type=range]::-moz-range-progress {
            @include slider-range();
        }

        input[type=range]::-ms-track {
            @include slider-range();
        }

        input[type=range]::-ms-fill-lower {
            display: none;
        }

        input[type=range]::-ms-fill-upper {
            display: none;
        }

        input[type=range]::-webkit-slider-thumb {
            @include slider-thumb();
        }

        input[type=range]::-moz-range-thumb {
            @include slider-thumb();
        }

        input[type=range]::-ms-thumb {
            @include slider-thumb();
        }
    }
}

.faq-listing-container {
    .faq-listing {
        margin: 1rem 0;

        .faq {
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid $gray;

            .question-container {
                cursor: pointer;
                display: flex;
                width: 100%;

                $icon-radius: 14px;

                .question-icon {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    margin: auto 1rem auto 0;
                    background-color: $gray-semidark;
                    color: $white;
                    border-radius: 100%;
                    padding: $icon-radius;
                    height: $icon-radius*2;
                    width: $icon-radius*2;
                    font-size: 44px;
                    transition: 200ms linear all;
                    transform: rotate(0deg);

                    &::after {
                        content: "\207A";
                        display: block;
                        position: absolute;
                        top: -1px;
                    }
                }

                .question {
                    margin-left: 3rem;
                }

                @media screen and (max-width: $screen-md) {
                    .question {
                        margin-left: 5rem;
                    }
                }
            }

            .answer {
                display: block;

                &.hidden {
                    display: none;
                }
            }

            &.expanded {
                padding-top: 2rem;
                padding-bottom: 0;

                .question-icon {
                    transition: 200ms linear all;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
