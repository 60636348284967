@import "../base/_variables.scss";

p {
    a:not(.button-standard) {
        @extend p;
        color: $gray-dark;
        text-decoration: none;
        font-weight: 600;
        position: relative;
        display: inline-block;
        margin: 0;

        &.underlined {
            @extend a.underlined;
        }

        &.inverted {
            @extend a.inverted;
        }
    }
}

a {
    position: relative;
    font-size: $base-fontsize * 1.7;
    text-decoration: none;
    color: $gray-dark;

    &.inverted {
        color: white;
    }

    &.unstyled {
        font-weight: 400 !important;
        color: $gray-dark;
    }

    &.unstyled-small {
        @extend p;
        text-decoration: none;
        color: $gray-dark;
    }

    &.small {
        font-size: $base-fontsize*1.3;
        text-decoration: none;
        font-family: $font-raleway;
    }

    &.p {
        @extend p;
    }
}

.link {
    &.link-down {
        background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_down.svg");
        background-repeat: no-repeat;
        width: 4rem;
        height: 4rem;
        margin-bottom: 2rem;

        &.link-down--white {
            background-image: inline-image("../../../wwwroot/Static/Images/eo_ob_down_white.svg");
        }
    }
}
