@import "_variables.scss";

body {
    font-size: $base-fontsize * 2.1;

    @media screen and (min-width: $screen-sm) {
        font-size: $base-fontsize * 1.5;
    }
}

span {
    &.italic {
        font-family: $font-abril-text-italic;
    }
}

.orange {
    color: $orange;
}

.error {
    color:red;
}

h1 {
    font-size: $base-fontsize * 7.5;
    font-family: $font-abril-text;
    letter-spacing: -0.4rem;

    &.heading-underlined {
        padding-bottom: 2rem;
        margin-bottom: 4rem;
    }

    &.medium {
        @extend h2;
        letter-spacing: -0.1rem;
    }

    &.small {
        font-size: $base-fontsize * 4.0;
        letter-spacing: 0;
        line-height: 1.2;
        padding-bottom: 0;

        @media screen and (min-width: $screen-md) {
            font-size: $base-fontsize * 3.0;
        }
    }

    &.italic {
        font-family: $font-abril-text-italic;
    }

    &.mini {
        @extend .button-text;
        letter-spacing: 0.18rem !important;
    }

    &.inverted {
        color: $white;
    }

    &.expanded {
        line-height: 1.2;
        letter-spacing: -0.1rem;
        font-size: $base-fontsize*5.8;
        
        @media screen and (min-width: $screen-sm) {
            font-size: $base-fontsize*7.5;
        }
    }
}

.heading-underlined {
    position: relative;
    margin-bottom: 6rem;

    &:after {
        position: absolute;
        bottom: -2rem;
        right: 50%;
        margin-right: -3rem;
        content: "";
        width: 6rem;
        height: 1px;
        background-color: $orange;
    }

    &.heading-underlined--smallmargin {
        margin-bottom: 1rem;
    }
}

h2 {
    font-size: $base-fontsize * 3.5;
    font-family: $font-abril-text;
    line-height: 1.15;

    &.inverted {
        color: $white;
    }

    &.orange {
        color: $orange;
        font-family: $font-abril-text-italic;
    }

    &.underlined {
        @extend .heading-underlined;
    }

    &.nowrap {
        white-space: nowrap;
    }

    &.small--underlined {
        @extend .underline;
        @extend .small;
        padding-bottom: 2rem;
        display: inline-block;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    &.small {
        font-family: $font-abril-text-italic;
        font-size: $base-fontsize * 3;

        @media screen and (max-width: $screen-sm) {
            font-size: $base-fontsize * 2.5;
        }
    }

    &.mediumlarge {
        line-height: 1.15;
        letter-spacing: 0;
        @extend h1;
    }

    &.large {
        letter-spacing: -0.1rem;
        font-size: $base-fontsize * 6;

        @media screen and (min-width: $screen-mlg) {
            font-size: $base-fontsize * 7;
        }

        @media screen and (min-width: $screen-lg) {
            font-size: $base-fontsize * 9.5;
        }
    }

    &.medium {
        line-height: 1.30;

        @media screen and (min-width: $screen-sm) {
            font-size: $base-fontsize * 4.6;
        }
    }

    &.italic {
        font-family: $font-abril-text-italic;
    }

    &.gray {
        color: $gray;
    }

    &.wide {
        letter-spacing: 0.1rem;
    }

    &.eventtitle {
        &.underline {
            padding-bottom: 0 !important;
        }

        font-weight: 600;
        font-size: $base-fontsize * 2.1;
        letter-spacing: 0;

        @media screen and (min-width: $screen-sm) {
            font-size: $base-fontsize * 1.5;
            letter-spacing: 0.03rem;
        }

        @extend h5;
    }
}

h3 {
    line-height: 1.2;
    font-size: $base-fontsize * 4.2;

    &.small {
        font-size: $base-fontsize * 3.37;
    }

    &.abril-text {
        font-family: $font-abril-text;
    }

    &.bold {
        font-weight:600;
    }
}

h4 {
    font-family: $font-abril-text;
    font-size: $base-fontsize * 3;
    letter-spacing: -0.06rem;

    &.inverted {
        color: $white;
    }

    &.small {
        font-family: $font-abril-text-italic;
        font-size: $base-fontsize * 3.0;
        line-height: 1.5;
    }

    &.mini {
        font-family: $font-abril-text-italic;
        font-size: $base-fontsize * 2.5;
        letter-spacing: 0.01rem;
    }

    &.underlined {
        @extend .underline;
        padding-bottom: $base-padding * 2;
        display: inline-block;
    }

    .black {
        font-family: $font-abril-text;
    }

    .orange {
        font-family: $font-abril-text-italic;
        color: $orange;
    }
}

h5 {
    @extend .button-text;
    letter-spacing: 0.18rem !important;
    line-height: 3rem;

    @media screen and (min-width: $screen-sm) {
        font-size: $base-fontsize * 1.7;
    }

    &.unstyled {
        font-weight: 400;
    }

    &.underlined {
        @extend .heading-underlined;
    }

    &.small {
        @extend p;
        font-weight: 400;
        color: $gray-dark;
        letter-spacing: 0.1rem !important;
    }

    &.inverted {
        color: $white !important;
    }

    &.subheading {
        margin-bottom: -2.5rem;
        font-size: $base-fontsize * 2.2;
        line-height: 3.8rem;

        @media screen and (min-width: $screen-sm) {
            font-size: $base-fontsize * 1.6;
            line-height: 3rem;
        }
    }

    &.underline {
        @extend .underline;
        padding-bottom: $base-padding;
        display: inline-block;
    }
}

h6 {
    font-size: $base-fontsize * 2.2;
    letter-spacing: 0.1rem;
    line-height: 1.4;

    @media screen and (min-width: $screen-sm) {
        font-size: $base-fontsize * 1.8;
    }

    &.large {
        font-size: $base-fontsize * 2;
    }

    &.italic {
        font-family: $font-abril-text-italic;
        font-size: $base-fontsize * 2.1;
        letter-spacing: 0.03rem;
    }

    &.small {
        font-size: $base-fontsize*1.2;
    }

    &.bold {
        font-weight: 600;
    }
}

i {
    font-family: $font-abril-text-italic;
}

.dark {
    > p {
        color: $white;
    }
}

.light {
    > p {
        color: $gray-dark;
    }
}

p {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: $base-fontsize * 2.1;
    line-height: 3.6rem;
    color: $gray-dark;
    letter-spacing: 0;


    @media screen and (min-width: $screen-sm) {
        line-height: 2.8rem;
        font-size: $base-fontsize * 1.5;
        letter-spacing: 0.03rem;
    }

    &.italic {
        line-height: 3.7rem;
        font-family: $font-abril-text-italic;
        font-size: $base-fontsize * 2.5;

        @media screen and (min-width: $screen-sm) {
            line-height: 3.1rem;
            font-size: $base-fontsize * 1.9;
        }
    }

    &.italic--small {
        line-height: 3.7rem;
        font-family: $font-abril-text-italic;
        font-size: $base-fontsize * 2.4;

        @media screen and (min-width: $screen-sm) {
            line-height: 2.8rem;
            font-size: $base-fontsize * 1.8;
        }
    }

    &.first-in-section {
        margin-top: 0;
    }

    &.last-in-section {
        margin-bottom: 0;
    }

    .dark {
        color: $white;
    }

    &.small {
        margin: 0;
        font-size: $base-fontsize * 1.73;
        line-height: 2.69rem;

        @media screen and (min-width: $screen-sm) {
            font-size: $base-fontsize * 1.28;
            line-height: 2.28rem;
        }
    }
}

label.link-label {
    font-weight: 400;
    letter-spacing: 0.2rem;
    font-size: $base-fontsize * 1.6;
    line-height: 3.3rem;

    @media screen and (min-width: $screen-sm) {
        font-size: $base-fontsize * 1.4;
        line-height: 2.8rem;
    }

    &.radio-label {
        text-transform: none;
    }
}

.underline {
    border-bottom: 1px solid $orange;

    &.underline--small {
        border-bottom: none;

        &:after {
            content: "";
            border-bottom: 1px solid $orange;
            width: 7rem;
            height: 1.5rem;
            display: block;
        }

        &.underline--small--center {
            &:after {
                margin-left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.overline {
    border-top: 1px solid $orange;
}

strong {
    font-weight: 600;
}

.uppercase {
    text-transform: uppercase;
}

.heading {
    &.heading-first-in-section {
        margin-top: $base-margin-large;
        margin-bottom: 6rem;
    }
}

/* text on buttons and such */
.button-text {
    font-family: $font-raleway;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-size: $base-fontsize*1.7;
    font-weight: 400;
}


.validation-summary-errors, .field-validation-error, .validation-summary-errors > ul > li {
    font-size: $base-fontsize * 2.1;
    letter-spacing: 0;

    @media screen and (min-width: $screen-sm) {
        font-size: $base-fontsize * 1.5;
        letter-spacing: 0.03rem;
    }

    color: $validation-color;

    ul {
        li {
            padding: $base-padding/2 0 $base-padding/2 0;
        }
    }
}

cite {
    font-size: $base-fontsize * 5.5;

    @media screen and (min-width: $screen-sm) {
        font-size: $base-fontsize * 7.5;
    }

    font-family: $font-abril-text-italic;
    line-height: 1.2;
    display: block;
    margin-top: -2rem;
}

.inverted {
    color: $white;
}

em {
    font-style: italic;
}

.quote {
    font-family: $font-raleway;
    font-style: italic;
}
