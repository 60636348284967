@import "../base/_variables.scss";

.arrow-down {
    height: 2.2rem;
    position: absolute;

    &:after {
        backface-visibility: hidden;
        transition: all 0.4s ease;
    }

    &.active {
        &:after {
            transform: rotate(225deg);
        }
    }

    &.small {
        &:after {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    &.inverted {
        &:after {
            border-color: $orange;
        }
    }

    &:after {
        display: inline-block;
        border: 1px solid $white;
        border-left: none;
        border-top: none;
        height: 2rem;
        width: 2rem;
        content: "";
        transform: rotate(45deg);
    }
}

.loading {
    .loader {
        display: inline-block;
    }

    .loader2 {
        display: inline-block;
    }
}

.loader {
    &.loader-visible {
        display: inline-block;
    }

    display: none;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(255, 255, 255, 0.2);
    border-right: 0.5em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
    border-left: 0.5em solid $white;

    &.loader-inverted {
        border-top: 0.5em solid rgba(243, 146, 0, 0.2);
        border-right: 0.5em solid rgba(243, 146, 0, 0.2);
        border-bottom: 0.5em solid rgba(243, 146, 0, 0.2);
        border-left: 0.5em solid $orange;
    }

    transform: translateZ(0);
    animation-name: load;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 50%;
    width: 4em;
    height: 4em;

    .loader:after {
        border-radius: 50%;
        width: 4em;
        height: 4em;
    }
}

.typing {
    span {
        animation-name: blinkdots;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        animation-delay: 0s;

        &:nth-child(2) {
            animation-delay: .2s;
        }

        &:nth-child(3) {
            animation-delay: .4s;
        }
    }
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes blinkdots {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
