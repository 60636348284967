﻿@font-face {
    font-family: 'AbrilText';
    src: url('/Static/Styles/fonts/subset-AbrilTextLt.woff2') format('woff2'),
         url('/Static/Styles/fonts/subset-AbrilTextLt.woff') format('woff'),
         url('/Static/Styles/fonts/subset-AbrilTextLt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AbrilText-Italic';
    src: url('/Static/Styles/fonts/subset-AbrilTextLt-Italic.woff2') format('woff2'),
         url('/Static/Styles/fonts/subset-AbrilTextLt-Italic.woff') format('woff'),
         url('/Static/Styles/fonts/subset-AbrilTextLt-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('/Static/Styles/fonts/subset-Raleway-SemiBold.woff2') format('woff2'),
        url('/Static/Styles/fonts/subset-Raleway-SemiBold.woff') format('woff'),
        url('/Static/Styles/fonts/subset-Raleway-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('/Static/Styles/fonts/subset-Raleway-Regular.woff2') format('woff2'),
        url('/Static/Styles/fonts/subset-Raleway-Regular.woff') format('woff'),
        url('/Static/Styles/fonts/subset-Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('/Static/Styles/fonts/subset-Raleway-Medium.woff2') format('woff2'),
        url('/Static/Styles/fonts/subset-Raleway-Medium.woff') format('woff'),
        url('/Static/Styles/fonts/subset-Raleway-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

